/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as common_message007 from './common-message.pb';
/**
 * Message implementation for get_started_common.ListCurrencyIndicatorGetStartedResponse
 */
export class ListCurrencyIndicatorGetStartedResponse implements GrpcMessage {
  static id = 'get_started_common.ListCurrencyIndicatorGetStartedResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListCurrencyIndicatorGetStartedResponse();
    ListCurrencyIndicatorGetStartedResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListCurrencyIndicatorGetStartedResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListCurrencyIndicatorGetStartedResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.CurrencyIndicatorModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.CurrencyIndicatorModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListCurrencyIndicatorGetStartedResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListCurrencyIndicatorGetStartedResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.CurrencyIndicatorModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.CurrencyIndicatorModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListCurrencyIndicatorGetStartedResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListCurrencyIndicatorGetStartedResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.CurrencyIndicatorModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListCurrencyIndicatorGetStartedResponse.refineValues(this);
  }
  get values(): common_message007.CurrencyIndicatorModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.CurrencyIndicatorModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListCurrencyIndicatorGetStartedResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListCurrencyIndicatorGetStartedResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListCurrencyIndicatorGetStartedResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListCurrencyIndicatorGetStartedResponse {
  /**
   * Standard JavaScript object representation for ListCurrencyIndicatorGetStartedResponse
   */
  export interface AsObject {
    values?: common_message007.CurrencyIndicatorModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListCurrencyIndicatorGetStartedResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.CurrencyIndicatorModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListFundingShareValueGetStartedResponse
 */
export class ListFundingShareValueGetStartedResponse implements GrpcMessage {
  static id = 'get_started_common.ListFundingShareValueGetStartedResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListFundingShareValueGetStartedResponse();
    ListFundingShareValueGetStartedResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListFundingShareValueGetStartedResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListFundingShareValueGetStartedResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.FundingShareValueModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.FundingShareValueModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListFundingShareValueGetStartedResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListFundingShareValueGetStartedResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.FundingShareValueModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.FundingShareValueModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListFundingShareValueGetStartedResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListFundingShareValueGetStartedResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.FundingShareValueModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListFundingShareValueGetStartedResponse.refineValues(this);
  }
  get values(): common_message007.FundingShareValueModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.FundingShareValueModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListFundingShareValueGetStartedResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListFundingShareValueGetStartedResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListFundingShareValueGetStartedResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListFundingShareValueGetStartedResponse {
  /**
   * Standard JavaScript object representation for ListFundingShareValueGetStartedResponse
   */
  export interface AsObject {
    values?: common_message007.FundingShareValueModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListFundingShareValueGetStartedResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.FundingShareValueModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListQuestionsResponse
 */
export class ListQuestionsResponse implements GrpcMessage {
  static id = 'get_started_common.ListQuestionsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListQuestionsResponse();
    ListQuestionsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListQuestionsResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListQuestionsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.QuestionModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.QuestionModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListQuestionsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListQuestionsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.QuestionModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.QuestionModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListQuestionsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListQuestionsResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.QuestionModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListQuestionsResponse.refineValues(this);
  }
  get values(): common_message007.QuestionModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.QuestionModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListQuestionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListQuestionsResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListQuestionsResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListQuestionsResponse {
  /**
   * Standard JavaScript object representation for ListQuestionsResponse
   */
  export interface AsObject {
    values?: common_message007.QuestionModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListQuestionsResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.QuestionModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListGoalCategoriesResponse
 */
export class ListGoalCategoriesResponse implements GrpcMessage {
  static id = 'get_started_common.ListGoalCategoriesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalCategoriesResponse();
    ListGoalCategoriesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalCategoriesResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalCategoriesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.GoalCategoryModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.GoalCategoryModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalCategoriesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalCategoriesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.GoalCategoryModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.GoalCategoryModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalCategoriesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListGoalCategoriesResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.GoalCategoryModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListGoalCategoriesResponse.refineValues(this);
  }
  get values(): common_message007.GoalCategoryModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.GoalCategoryModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalCategoriesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalCategoriesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalCategoriesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListGoalCategoriesResponse {
  /**
   * Standard JavaScript object representation for ListGoalCategoriesResponse
   */
  export interface AsObject {
    values?: common_message007.GoalCategoryModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListGoalCategoriesResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.GoalCategoryModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListRiskProfilesResponse
 */
export class ListRiskProfilesResponse implements GrpcMessage {
  static id = 'get_started_common.ListRiskProfilesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListRiskProfilesResponse();
    ListRiskProfilesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListRiskProfilesResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListRiskProfilesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.RiskProfileModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.RiskProfileModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListRiskProfilesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListRiskProfilesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.RiskProfileModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.RiskProfileModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListRiskProfilesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListRiskProfilesResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.RiskProfileModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListRiskProfilesResponse.refineValues(this);
  }
  get values(): common_message007.RiskProfileModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.RiskProfileModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListRiskProfilesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListRiskProfilesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListRiskProfilesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListRiskProfilesResponse {
  /**
   * Standard JavaScript object representation for ListRiskProfilesResponse
   */
  export interface AsObject {
    values?: common_message007.RiskProfileModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListRiskProfilesResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.RiskProfileModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListRiskLevelsResponse
 */
export class ListRiskLevelsResponse implements GrpcMessage {
  static id = 'get_started_common.ListRiskLevelsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListRiskLevelsResponse();
    ListRiskLevelsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListRiskLevelsResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListRiskLevelsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.RiskLevelModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.RiskLevelModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListRiskLevelsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListRiskLevelsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.RiskLevelModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.RiskLevelModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListRiskLevelsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListRiskLevelsResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.RiskLevelModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListRiskLevelsResponse.refineValues(this);
  }
  get values(): common_message007.RiskLevelModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.RiskLevelModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListRiskLevelsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListRiskLevelsResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListRiskLevelsResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListRiskLevelsResponse {
  /**
   * Standard JavaScript object representation for ListRiskLevelsResponse
   */
  export interface AsObject {
    values?: common_message007.RiskLevelModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListRiskLevelsResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.RiskLevelModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListPortfoliosResponse
 */
export class ListPortfoliosResponse implements GrpcMessage {
  static id = 'get_started_common.ListPortfoliosResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListPortfoliosResponse();
    ListPortfoliosResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListPortfoliosResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListPortfoliosResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.PortfolioModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.PortfolioModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListPortfoliosResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListPortfoliosResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.PortfolioModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.PortfolioModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListPortfoliosResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListPortfoliosResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.PortfolioModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListPortfoliosResponse.refineValues(this);
  }
  get values(): common_message007.PortfolioModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.PortfolioModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListPortfoliosResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListPortfoliosResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListPortfoliosResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListPortfoliosResponse {
  /**
   * Standard JavaScript object representation for ListPortfoliosResponse
   */
  export interface AsObject {
    values?: common_message007.PortfolioModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListPortfoliosResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.PortfolioModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListTimeHorizonsResponse
 */
export class ListTimeHorizonsResponse implements GrpcMessage {
  static id = 'get_started_common.ListTimeHorizonsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListTimeHorizonsResponse();
    ListTimeHorizonsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListTimeHorizonsResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListTimeHorizonsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.TimeHorizonModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.TimeHorizonModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListTimeHorizonsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListTimeHorizonsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.TimeHorizonModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.TimeHorizonModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListTimeHorizonsResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListTimeHorizonsResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.TimeHorizonModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListTimeHorizonsResponse.refineValues(this);
  }
  get values(): common_message007.TimeHorizonModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.TimeHorizonModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListTimeHorizonsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListTimeHorizonsResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListTimeHorizonsResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListTimeHorizonsResponse {
  /**
   * Standard JavaScript object representation for ListTimeHorizonsResponse
   */
  export interface AsObject {
    values?: common_message007.TimeHorizonModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListTimeHorizonsResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.TimeHorizonModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListFinancialEntitiesResponse
 */
export class ListFinancialEntitiesResponse implements GrpcMessage {
  static id = 'get_started_common.ListFinancialEntitiesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListFinancialEntitiesResponse();
    ListFinancialEntitiesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListFinancialEntitiesResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListFinancialEntitiesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.FinancialEntityModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.FinancialEntityModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListFinancialEntitiesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListFinancialEntitiesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.FinancialEntityModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.FinancialEntityModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListFinancialEntitiesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListFinancialEntitiesResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.FinancialEntityModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListFinancialEntitiesResponse.refineValues(this);
  }
  get values(): common_message007.FinancialEntityModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.FinancialEntityModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListFinancialEntitiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListFinancialEntitiesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListFinancialEntitiesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListFinancialEntitiesResponse {
  /**
   * Standard JavaScript object representation for ListFinancialEntitiesResponse
   */
  export interface AsObject {
    values?: common_message007.FinancialEntityModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListFinancialEntitiesResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.FinancialEntityModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListInvestmentStrategiesResponse
 */
export class ListInvestmentStrategiesResponse implements GrpcMessage {
  static id = 'get_started_common.ListInvestmentStrategiesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListInvestmentStrategiesResponse();
    ListInvestmentStrategiesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListInvestmentStrategiesResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListInvestmentStrategiesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.InvestmentStrategyModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.InvestmentStrategyModel
              .deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListInvestmentStrategiesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListInvestmentStrategiesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.InvestmentStrategyModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.InvestmentStrategyModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListInvestmentStrategiesResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListInvestmentStrategiesResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.InvestmentStrategyModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListInvestmentStrategiesResponse.refineValues(this);
  }
  get values(): common_message007.InvestmentStrategyModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.InvestmentStrategyModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListInvestmentStrategiesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListInvestmentStrategiesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListInvestmentStrategiesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListInvestmentStrategiesResponse {
  /**
   * Standard JavaScript object representation for ListInvestmentStrategiesResponse
   */
  export interface AsObject {
    values?: common_message007.InvestmentStrategyModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListInvestmentStrategiesResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.InvestmentStrategyModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListPlanCalculationResponse
 */
export class ListPlanCalculationResponse implements GrpcMessage {
  static id = 'get_started_common.ListPlanCalculationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListPlanCalculationResponse();
    ListPlanCalculationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListPlanCalculationResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListPlanCalculationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.PlanCalculationModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.PlanCalculationModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListPlanCalculationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListPlanCalculationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.PlanCalculationModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.PlanCalculationModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListPlanCalculationResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListPlanCalculationResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.PlanCalculationModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListPlanCalculationResponse.refineValues(this);
  }
  get values(): common_message007.PlanCalculationModel[] | undefined {
    return this._values;
  }
  set values(value: common_message007.PlanCalculationModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListPlanCalculationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListPlanCalculationResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListPlanCalculationResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListPlanCalculationResponse {
  /**
   * Standard JavaScript object representation for ListPlanCalculationResponse
   */
  export interface AsObject {
    values?: common_message007.PlanCalculationModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListPlanCalculationResponse
   */
  export interface AsProtobufJSON {
    values: common_message007.PlanCalculationModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.ListGoalRiskLevelCalculationResponse
 */
export class ListGoalRiskLevelCalculationResponse implements GrpcMessage {
  static id = 'get_started_common.ListGoalRiskLevelCalculationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalRiskLevelCalculationResponse();
    ListGoalRiskLevelCalculationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalRiskLevelCalculationResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalRiskLevelCalculationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.GoalRiskLevelCalculationModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.GoalRiskLevelCalculationModel
              .deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalRiskLevelCalculationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalRiskLevelCalculationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message007.GoalRiskLevelCalculationModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message007.GoalRiskLevelCalculationModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalRiskLevelCalculationResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListGoalRiskLevelCalculationResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message007.GoalRiskLevelCalculationModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListGoalRiskLevelCalculationResponse.refineValues(this);
  }
  get values(): common_message007.GoalRiskLevelCalculationModel[] | undefined {
    return this._values;
  }
  set values(
    value: common_message007.GoalRiskLevelCalculationModel[] | undefined
  ) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalRiskLevelCalculationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalRiskLevelCalculationResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalRiskLevelCalculationResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListGoalRiskLevelCalculationResponse {
  /**
   * Standard JavaScript object representation for ListGoalRiskLevelCalculationResponse
   */
  export interface AsObject {
    values?: common_message007.GoalRiskLevelCalculationModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListGoalRiskLevelCalculationResponse
   */
  export interface AsProtobufJSON {
    values:
      | common_message007.GoalRiskLevelCalculationModel.AsProtobufJSON[]
      | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for get_started_common.CalculatePlanRequest
 */
export class CalculatePlanRequest implements GrpcMessage {
  static id = 'get_started_common.CalculatePlanRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculatePlanRequest();
    CalculatePlanRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculatePlanRequest) {
    _instance.answers = _instance.answers || [];
    _instance.email = _instance.email || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculatePlanRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message007.UserResultCalculateAnswerModel();
          _reader.readMessage(
            messageInitializer1,
            common_message007.UserResultCalculateAnswerModel
              .deserializeBinaryFromReader
          );
          (_instance.answers = _instance.answers || []).push(
            messageInitializer1
          );
          break;
        case 2:
          _instance.email = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CalculatePlanRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculatePlanRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.answers && _instance.answers.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.answers as any,
        common_message007.UserResultCalculateAnswerModel.serializeBinaryToWriter
      );
    }
    if (_instance.email) {
      _writer.writeString(2, _instance.email);
    }
  }

  private _answers?: common_message007.UserResultCalculateAnswerModel[];
  private _email: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculatePlanRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CalculatePlanRequest.AsObject>) {
    _value = _value || {};
    this.answers = (_value.answers || []).map(
      m => new common_message007.UserResultCalculateAnswerModel(m)
    );
    this.email = _value.email;
    CalculatePlanRequest.refineValues(this);
  }
  get answers():
    | common_message007.UserResultCalculateAnswerModel[]
    | undefined {
    return this._answers;
  }
  set answers(
    value: common_message007.UserResultCalculateAnswerModel[] | undefined
  ) {
    this._answers = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculatePlanRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculatePlanRequest.AsObject {
    return {
      answers: (this.answers || []).map(m => m.toObject()),
      email: this.email
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculatePlanRequest.AsProtobufJSON {
    return {
      answers: (this.answers || []).map(m => m.toProtobufJSON(options)),
      email: this.email
    };
  }
}
export module CalculatePlanRequest {
  /**
   * Standard JavaScript object representation for CalculatePlanRequest
   */
  export interface AsObject {
    answers?: common_message007.UserResultCalculateAnswerModel.AsObject[];
    email: string;
  }

  /**
   * Protobuf JSON representation for CalculatePlanRequest
   */
  export interface AsProtobufJSON {
    answers:
      | common_message007.UserResultCalculateAnswerModel.AsProtobufJSON[]
      | null;
    email: string;
  }
}

/**
 * Message implementation for get_started_common.CalculatePlanResponse
 */
export class CalculatePlanResponse implements GrpcMessage {
  static id = 'get_started_common.CalculatePlanResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculatePlanResponse();
    CalculatePlanResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculatePlanResponse) {
    _instance.plan = _instance.plan || undefined;
    _instance.goal = _instance.goal || undefined;
    _instance.user = _instance.user || undefined;
    _instance.econsult = _instance.econsult || undefined;
    _instance.answers = _instance.answers || [];
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculatePlanResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.plan = new common_message007.UserResultCalculatePlanModel();
          _reader.readMessage(
            _instance.plan,
            common_message007.UserResultCalculatePlanModel
              .deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.goal = new common_message007.UserResultCalculateGoalModel();
          _reader.readMessage(
            _instance.goal,
            common_message007.UserResultCalculateGoalModel
              .deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.user = new common_message007.UserResultCalculateUserModel();
          _reader.readMessage(
            _instance.user,
            common_message007.UserResultCalculateUserModel
              .deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.econsult = new common_message007.UserResultCalculateEconsultModel();
          _reader.readMessage(
            _instance.econsult,
            common_message007.UserResultCalculateEconsultModel
              .deserializeBinaryFromReader
          );
          break;
        case 6:
          const messageInitializer6 = new common_message007.UserResultCalculateAnswerModel();
          _reader.readMessage(
            messageInitializer6,
            common_message007.UserResultCalculateAnswerModel
              .deserializeBinaryFromReader
          );
          (_instance.answers = _instance.answers || []).push(
            messageInitializer6
          );
          break;
        case 7:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CalculatePlanResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculatePlanResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.plan) {
      _writer.writeMessage(
        1,
        _instance.plan as any,
        common_message007.UserResultCalculatePlanModel.serializeBinaryToWriter
      );
    }
    if (_instance.goal) {
      _writer.writeMessage(
        2,
        _instance.goal as any,
        common_message007.UserResultCalculateGoalModel.serializeBinaryToWriter
      );
    }
    if (_instance.user) {
      _writer.writeMessage(
        3,
        _instance.user as any,
        common_message007.UserResultCalculateUserModel.serializeBinaryToWriter
      );
    }
    if (_instance.econsult) {
      _writer.writeMessage(
        5,
        _instance.econsult as any,
        common_message007.UserResultCalculateEconsultModel
          .serializeBinaryToWriter
      );
    }
    if (_instance.answers && _instance.answers.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.answers as any,
        common_message007.UserResultCalculateAnswerModel.serializeBinaryToWriter
      );
    }
    if (_instance.id) {
      _writer.writeInt32(7, _instance.id);
    }
  }

  private _plan?: common_message007.UserResultCalculatePlanModel;
  private _goal?: common_message007.UserResultCalculateGoalModel;
  private _user?: common_message007.UserResultCalculateUserModel;
  private _econsult?: common_message007.UserResultCalculateEconsultModel;
  private _answers?: common_message007.UserResultCalculateAnswerModel[];
  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculatePlanResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CalculatePlanResponse.AsObject>) {
    _value = _value || {};
    this.plan = _value.plan
      ? new common_message007.UserResultCalculatePlanModel(_value.plan)
      : undefined;
    this.goal = _value.goal
      ? new common_message007.UserResultCalculateGoalModel(_value.goal)
      : undefined;
    this.user = _value.user
      ? new common_message007.UserResultCalculateUserModel(_value.user)
      : undefined;
    this.econsult = _value.econsult
      ? new common_message007.UserResultCalculateEconsultModel(_value.econsult)
      : undefined;
    this.answers = (_value.answers || []).map(
      m => new common_message007.UserResultCalculateAnswerModel(m)
    );
    this.id = _value.id;
    CalculatePlanResponse.refineValues(this);
  }
  get plan(): common_message007.UserResultCalculatePlanModel | undefined {
    return this._plan;
  }
  set plan(value: common_message007.UserResultCalculatePlanModel | undefined) {
    this._plan = value;
  }
  get goal(): common_message007.UserResultCalculateGoalModel | undefined {
    return this._goal;
  }
  set goal(value: common_message007.UserResultCalculateGoalModel | undefined) {
    this._goal = value;
  }
  get user(): common_message007.UserResultCalculateUserModel | undefined {
    return this._user;
  }
  set user(value: common_message007.UserResultCalculateUserModel | undefined) {
    this._user = value;
  }
  get econsult():
    | common_message007.UserResultCalculateEconsultModel
    | undefined {
    return this._econsult;
  }
  set econsult(
    value: common_message007.UserResultCalculateEconsultModel | undefined
  ) {
    this._econsult = value;
  }
  get answers():
    | common_message007.UserResultCalculateAnswerModel[]
    | undefined {
    return this._answers;
  }
  set answers(
    value: common_message007.UserResultCalculateAnswerModel[] | undefined
  ) {
    this._answers = value;
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculatePlanResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculatePlanResponse.AsObject {
    return {
      plan: this.plan ? this.plan.toObject() : undefined,
      goal: this.goal ? this.goal.toObject() : undefined,
      user: this.user ? this.user.toObject() : undefined,
      econsult: this.econsult ? this.econsult.toObject() : undefined,
      answers: (this.answers || []).map(m => m.toObject()),
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculatePlanResponse.AsProtobufJSON {
    return {
      plan: this.plan ? this.plan.toProtobufJSON(options) : null,
      goal: this.goal ? this.goal.toProtobufJSON(options) : null,
      user: this.user ? this.user.toProtobufJSON(options) : null,
      econsult: this.econsult ? this.econsult.toProtobufJSON(options) : null,
      answers: (this.answers || []).map(m => m.toProtobufJSON(options)),
      id: this.id
    };
  }
}
export module CalculatePlanResponse {
  /**
   * Standard JavaScript object representation for CalculatePlanResponse
   */
  export interface AsObject {
    plan?: common_message007.UserResultCalculatePlanModel.AsObject;
    goal?: common_message007.UserResultCalculateGoalModel.AsObject;
    user?: common_message007.UserResultCalculateUserModel.AsObject;
    econsult?: common_message007.UserResultCalculateEconsultModel.AsObject;
    answers?: common_message007.UserResultCalculateAnswerModel.AsObject[];
    id: number;
  }

  /**
   * Protobuf JSON representation for CalculatePlanResponse
   */
  export interface AsProtobufJSON {
    plan: common_message007.UserResultCalculatePlanModel.AsProtobufJSON | null;
    goal: common_message007.UserResultCalculateGoalModel.AsProtobufJSON | null;
    user: common_message007.UserResultCalculateUserModel.AsProtobufJSON | null;
    econsult: common_message007.UserResultCalculateEconsultModel.AsProtobufJSON | null;
    answers:
      | common_message007.UserResultCalculateAnswerModel.AsProtobufJSON[]
      | null;
    id: number;
  }
}

/**
 * Message implementation for get_started_common.ExistsUserRequest
 */
export class ExistsUserRequest implements GrpcMessage {
  static id = 'get_started_common.ExistsUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExistsUserRequest();
    ExistsUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExistsUserRequest) {
    _instance.rut = _instance.rut || undefined;
    _instance.email = _instance.email || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExistsUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rut = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.rut,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.email = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.email,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ExistsUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExistsUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.rut) {
      _writer.writeMessage(
        1,
        _instance.rut as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.email) {
      _writer.writeMessage(
        2,
        _instance.email as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _rut?: googleProtobuf000.StringValue;
  private _email?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExistsUserRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExistsUserRequest.AsObject>) {
    _value = _value || {};
    this.rut = _value.rut
      ? new googleProtobuf000.StringValue(_value.rut)
      : undefined;
    this.email = _value.email
      ? new googleProtobuf000.StringValue(_value.email)
      : undefined;
    ExistsUserRequest.refineValues(this);
  }
  get rut(): googleProtobuf000.StringValue | undefined {
    return this._rut;
  }
  set rut(value: googleProtobuf000.StringValue | undefined) {
    this._rut = value;
  }
  get email(): googleProtobuf000.StringValue | undefined {
    return this._email;
  }
  set email(value: googleProtobuf000.StringValue | undefined) {
    this._email = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExistsUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExistsUserRequest.AsObject {
    return {
      rut: this.rut ? this.rut.toObject() : undefined,
      email: this.email ? this.email.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExistsUserRequest.AsProtobufJSON {
    return {
      rut: this.rut ? this.rut.toProtobufJSON(options) : null,
      email: this.email ? this.email.toProtobufJSON(options) : null
    };
  }
}
export module ExistsUserRequest {
  /**
   * Standard JavaScript object representation for ExistsUserRequest
   */
  export interface AsObject {
    rut?: googleProtobuf000.StringValue.AsObject;
    email?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for ExistsUserRequest
   */
  export interface AsProtobufJSON {
    rut: googleProtobuf000.StringValue.AsProtobufJSON | null;
    email: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for get_started_common.ExistsUserResponse
 */
export class ExistsUserResponse implements GrpcMessage {
  static id = 'get_started_common.ExistsUserResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExistsUserResponse();
    ExistsUserResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExistsUserResponse) {
    _instance.exists = _instance.exists || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExistsUserResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.exists = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ExistsUserResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExistsUserResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.exists) {
      _writer.writeBool(1, _instance.exists);
    }
  }

  private _exists: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExistsUserResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExistsUserResponse.AsObject>) {
    _value = _value || {};
    this.exists = _value.exists;
    ExistsUserResponse.refineValues(this);
  }
  get exists(): boolean {
    return this._exists;
  }
  set exists(value: boolean) {
    this._exists = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExistsUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExistsUserResponse.AsObject {
    return {
      exists: this.exists
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExistsUserResponse.AsProtobufJSON {
    return {
      exists: this.exists
    };
  }
}
export module ExistsUserResponse {
  /**
   * Standard JavaScript object representation for ExistsUserResponse
   */
  export interface AsObject {
    exists: boolean;
  }

  /**
   * Protobuf JSON representation for ExistsUserResponse
   */
  export interface AsProtobufJSON {
    exists: boolean;
  }
}

/**
 * Message implementation for get_started_common.RegisterUserRequest
 */
export class RegisterUserRequest implements GrpcMessage {
  static id = 'get_started_common.RegisterUserRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RegisterUserRequest();
    RegisterUserRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RegisterUserRequest) {
    _instance.surname = _instance.surname || '';
    _instance.phone = _instance.phone || '';
    _instance.email = _instance.email || '';
    _instance.firstame = _instance.firstame || '';
    _instance.password = _instance.password || '';
    _instance.rut = _instance.rut || '';
    _instance.middlename = _instance.middlename || '';
    _instance.maidenname = _instance.maidenname || '';
    _instance.refCode = _instance.refCode || '';
    _instance.resultCalculate = _instance.resultCalculate || undefined;
    _instance.original = _instance.original || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RegisterUserRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.surname = _reader.readString();
          break;
        case 2:
          _instance.phone = _reader.readString();
          break;
        case 3:
          _instance.email = _reader.readString();
          break;
        case 4:
          _instance.firstame = _reader.readString();
          break;
        case 5:
          _instance.password = _reader.readString();
          break;
        case 6:
          _instance.rut = _reader.readString();
          break;
        case 7:
          _instance.middlename = _reader.readString();
          break;
        case 8:
          _instance.maidenname = _reader.readString();
          break;
        case 9:
          _instance.refCode = _reader.readString();
          break;
        case 10:
          _instance.resultCalculate = new common_message007.UserResultCalculateModel();
          _reader.readMessage(
            _instance.resultCalculate,
            common_message007.UserResultCalculateModel
              .deserializeBinaryFromReader
          );
          break;
        case 11:
          _instance.original = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    RegisterUserRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RegisterUserRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.surname) {
      _writer.writeString(1, _instance.surname);
    }
    if (_instance.phone) {
      _writer.writeString(2, _instance.phone);
    }
    if (_instance.email) {
      _writer.writeString(3, _instance.email);
    }
    if (_instance.firstame) {
      _writer.writeString(4, _instance.firstame);
    }
    if (_instance.password) {
      _writer.writeString(5, _instance.password);
    }
    if (_instance.rut) {
      _writer.writeString(6, _instance.rut);
    }
    if (_instance.middlename) {
      _writer.writeString(7, _instance.middlename);
    }
    if (_instance.maidenname) {
      _writer.writeString(8, _instance.maidenname);
    }
    if (_instance.refCode) {
      _writer.writeString(9, _instance.refCode);
    }
    if (_instance.resultCalculate) {
      _writer.writeMessage(
        10,
        _instance.resultCalculate as any,
        common_message007.UserResultCalculateModel.serializeBinaryToWriter
      );
    }
    if (_instance.original) {
      _writer.writeString(11, _instance.original);
    }
  }

  private _surname: string;
  private _phone: string;
  private _email: string;
  private _firstame: string;
  private _password: string;
  private _rut: string;
  private _middlename: string;
  private _maidenname: string;
  private _refCode: string;
  private _resultCalculate?: common_message007.UserResultCalculateModel;
  private _original: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RegisterUserRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<RegisterUserRequest.AsObject>) {
    _value = _value || {};
    this.surname = _value.surname;
    this.phone = _value.phone;
    this.email = _value.email;
    this.firstame = _value.firstame;
    this.password = _value.password;
    this.rut = _value.rut;
    this.middlename = _value.middlename;
    this.maidenname = _value.maidenname;
    this.refCode = _value.refCode;
    this.resultCalculate = _value.resultCalculate
      ? new common_message007.UserResultCalculateModel(_value.resultCalculate)
      : undefined;
    this.original = _value.original;
    RegisterUserRequest.refineValues(this);
  }
  get surname(): string {
    return this._surname;
  }
  set surname(value: string) {
    this._surname = value;
  }
  get phone(): string {
    return this._phone;
  }
  set phone(value: string) {
    this._phone = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get firstame(): string {
    return this._firstame;
  }
  set firstame(value: string) {
    this._firstame = value;
  }
  get password(): string {
    return this._password;
  }
  set password(value: string) {
    this._password = value;
  }
  get rut(): string {
    return this._rut;
  }
  set rut(value: string) {
    this._rut = value;
  }
  get middlename(): string {
    return this._middlename;
  }
  set middlename(value: string) {
    this._middlename = value;
  }
  get maidenname(): string {
    return this._maidenname;
  }
  set maidenname(value: string) {
    this._maidenname = value;
  }
  get refCode(): string {
    return this._refCode;
  }
  set refCode(value: string) {
    this._refCode = value;
  }
  get resultCalculate():
    | common_message007.UserResultCalculateModel
    | undefined {
    return this._resultCalculate;
  }
  set resultCalculate(
    value: common_message007.UserResultCalculateModel | undefined
  ) {
    this._resultCalculate = value;
  }
  get original(): string {
    return this._original;
  }
  set original(value: string) {
    this._original = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RegisterUserRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RegisterUserRequest.AsObject {
    return {
      surname: this.surname,
      phone: this.phone,
      email: this.email,
      firstame: this.firstame,
      password: this.password,
      rut: this.rut,
      middlename: this.middlename,
      maidenname: this.maidenname,
      refCode: this.refCode,
      resultCalculate: this.resultCalculate
        ? this.resultCalculate.toObject()
        : undefined,
      original: this.original
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RegisterUserRequest.AsProtobufJSON {
    return {
      surname: this.surname,
      phone: this.phone,
      email: this.email,
      firstame: this.firstame,
      password: this.password,
      rut: this.rut,
      middlename: this.middlename,
      maidenname: this.maidenname,
      refCode: this.refCode,
      resultCalculate: this.resultCalculate
        ? this.resultCalculate.toProtobufJSON(options)
        : null,
      original: this.original
    };
  }
}
export module RegisterUserRequest {
  /**
   * Standard JavaScript object representation for RegisterUserRequest
   */
  export interface AsObject {
    surname: string;
    phone: string;
    email: string;
    firstame: string;
    password: string;
    rut: string;
    middlename: string;
    maidenname: string;
    refCode: string;
    resultCalculate?: common_message007.UserResultCalculateModel.AsObject;
    original: string;
  }

  /**
   * Protobuf JSON representation for RegisterUserRequest
   */
  export interface AsProtobufJSON {
    surname: string;
    phone: string;
    email: string;
    firstame: string;
    password: string;
    rut: string;
    middlename: string;
    maidenname: string;
    refCode: string;
    resultCalculate: common_message007.UserResultCalculateModel.AsProtobufJSON | null;
    original: string;
  }
}

/**
 * Message implementation for get_started_common.RegisterUserResponse
 */
export class RegisterUserResponse implements GrpcMessage {
  static id = 'get_started_common.RegisterUserResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RegisterUserResponse();
    RegisterUserResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RegisterUserResponse) {
    _instance.correct = _instance.correct || false;
    _instance.error = _instance.error || undefined;
    _instance.user = _instance.user || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RegisterUserResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.correct = _reader.readBool();
          break;
        case 2:
          _instance.error = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.error,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.user = new common_message007.UserModel();
          _reader.readMessage(
            _instance.user,
            common_message007.UserModel.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RegisterUserResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RegisterUserResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.correct) {
      _writer.writeBool(1, _instance.correct);
    }
    if (_instance.error) {
      _writer.writeMessage(
        2,
        _instance.error as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
    if (_instance.user) {
      _writer.writeMessage(
        3,
        _instance.user as any,
        common_message007.UserModel.serializeBinaryToWriter
      );
    }
  }

  private _correct: boolean;
  private _error?: googleProtobuf000.StringValue;
  private _user?: common_message007.UserModel;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RegisterUserResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<RegisterUserResponse.AsObject>) {
    _value = _value || {};
    this.correct = _value.correct;
    this.error = _value.error
      ? new googleProtobuf000.StringValue(_value.error)
      : undefined;
    this.user = _value.user
      ? new common_message007.UserModel(_value.user)
      : undefined;
    RegisterUserResponse.refineValues(this);
  }
  get correct(): boolean {
    return this._correct;
  }
  set correct(value: boolean) {
    this._correct = value;
  }
  get error(): googleProtobuf000.StringValue | undefined {
    return this._error;
  }
  set error(value: googleProtobuf000.StringValue | undefined) {
    this._error = value;
  }
  get user(): common_message007.UserModel | undefined {
    return this._user;
  }
  set user(value: common_message007.UserModel | undefined) {
    this._user = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RegisterUserResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RegisterUserResponse.AsObject {
    return {
      correct: this.correct,
      error: this.error ? this.error.toObject() : undefined,
      user: this.user ? this.user.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RegisterUserResponse.AsProtobufJSON {
    return {
      correct: this.correct,
      error: this.error ? this.error.toProtobufJSON(options) : null,
      user: this.user ? this.user.toProtobufJSON(options) : null
    };
  }
}
export module RegisterUserResponse {
  /**
   * Standard JavaScript object representation for RegisterUserResponse
   */
  export interface AsObject {
    correct: boolean;
    error?: googleProtobuf000.StringValue.AsObject;
    user?: common_message007.UserModel.AsObject;
  }

  /**
   * Protobuf JSON representation for RegisterUserResponse
   */
  export interface AsProtobufJSON {
    correct: boolean;
    error: googleProtobuf000.StringValue.AsProtobufJSON | null;
    user: common_message007.UserModel.AsProtobufJSON | null;
  }
}

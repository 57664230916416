/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as googleProtobuf006 from '@ngx-grpc/well-known-types';
import * as file_message007 from './file-message.pb';
import * as common_message008 from './common-message.pb';
/**
 * Message implementation for goal_transaction.GenerateTransactionFundsRequest
 */
export class GenerateTransactionFundsRequest implements GrpcMessage {
  static id = 'goal_transaction.GenerateTransactionFundsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GenerateTransactionFundsRequest();
    GenerateTransactionFundsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GenerateTransactionFundsRequest) {
    _instance.goalTransactionId = _instance.goalTransactionId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GenerateTransactionFundsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.goalTransactionId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    GenerateTransactionFundsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GenerateTransactionFundsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalTransactionId) {
      _writer.writeInt32(2, _instance.goalTransactionId);
    }
  }

  private _goalTransactionId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GenerateTransactionFundsRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GenerateTransactionFundsRequest.AsObject>
  ) {
    _value = _value || {};
    this.goalTransactionId = _value.goalTransactionId;
    GenerateTransactionFundsRequest.refineValues(this);
  }
  get goalTransactionId(): number {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: number) {
    this._goalTransactionId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GenerateTransactionFundsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GenerateTransactionFundsRequest.AsObject {
    return {
      goalTransactionId: this.goalTransactionId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GenerateTransactionFundsRequest.AsProtobufJSON {
    return {
      goalTransactionId: this.goalTransactionId
    };
  }
}
export module GenerateTransactionFundsRequest {
  /**
   * Standard JavaScript object representation for GenerateTransactionFundsRequest
   */
  export interface AsObject {
    goalTransactionId: number;
  }

  /**
   * Protobuf JSON representation for GenerateTransactionFundsRequest
   */
  export interface AsProtobufJSON {
    goalTransactionId: number;
  }
}

/**
 * Message implementation for goal_transaction.GenerateTransactionFundsResponse
 */
export class GenerateTransactionFundsResponse implements GrpcMessage {
  static id = 'goal_transaction.GenerateTransactionFundsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GenerateTransactionFundsResponse();
    GenerateTransactionFundsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GenerateTransactionFundsResponse) {
    _instance.ok = _instance.ok || false;
    _instance.warningsMsg = _instance.warningsMsg || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GenerateTransactionFundsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          (_instance.warningsMsg = _instance.warningsMsg || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    GenerateTransactionFundsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GenerateTransactionFundsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.warningsMsg && _instance.warningsMsg.length) {
      _writer.writeRepeatedString(2, _instance.warningsMsg);
    }
  }

  private _ok: boolean;
  private _warningsMsg: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GenerateTransactionFundsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GenerateTransactionFundsResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.warningsMsg = (_value.warningsMsg || []).slice();
    GenerateTransactionFundsResponse.refineValues(this);
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }
  get warningsMsg(): string[] {
    return this._warningsMsg;
  }
  set warningsMsg(value: string[]) {
    this._warningsMsg = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GenerateTransactionFundsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GenerateTransactionFundsResponse.AsObject {
    return {
      ok: this.ok,
      warningsMsg: (this.warningsMsg || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GenerateTransactionFundsResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      warningsMsg: (this.warningsMsg || []).slice()
    };
  }
}
export module GenerateTransactionFundsResponse {
  /**
   * Standard JavaScript object representation for GenerateTransactionFundsResponse
   */
  export interface AsObject {
    ok: boolean;
    warningsMsg: string[];
  }

  /**
   * Protobuf JSON representation for GenerateTransactionFundsResponse
   */
  export interface AsProtobufJSON {
    ok: boolean;
    warningsMsg: string[];
  }
}

/**
 * Message implementation for goal_transaction.ProcessTransactionManualRequest
 */
export class ProcessTransactionManualRequest implements GrpcMessage {
  static id = 'goal_transaction.ProcessTransactionManualRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProcessTransactionManualRequest();
    ProcessTransactionManualRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProcessTransactionManualRequest) {
    _instance.transactionsByFunding = _instance.transactionsByFunding || [];
    _instance.goalTransactionId = _instance.goalTransactionId || 0;
    _instance.realAmount = _instance.realAmount || 0;
    _instance.totalFundings = _instance.totalFundings || 0;
    _instance.totalCosts = _instance.totalCosts || 0;
    _instance.sendEmail = _instance.sendEmail || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ProcessTransactionManualRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new TransactionsByFunding();
          _reader.readMessage(
            messageInitializer1,
            TransactionsByFunding.deserializeBinaryFromReader
          );
          (_instance.transactionsByFunding =
            _instance.transactionsByFunding || []).push(messageInitializer1);
          break;
        case 2:
          _instance.goalTransactionId = _reader.readInt32();
          break;
        case 3:
          _instance.realAmount = _reader.readDouble();
          break;
        case 4:
          _instance.totalFundings = _reader.readDouble();
          break;
        case 5:
          _instance.totalCosts = _reader.readDouble();
          break;
        case 6:
          _instance.sendEmail = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ProcessTransactionManualRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ProcessTransactionManualRequest,
    _writer: BinaryWriter
  ) {
    if (
      _instance.transactionsByFunding &&
      _instance.transactionsByFunding.length
    ) {
      _writer.writeRepeatedMessage(
        1,
        _instance.transactionsByFunding as any,
        TransactionsByFunding.serializeBinaryToWriter
      );
    }
    if (_instance.goalTransactionId) {
      _writer.writeInt32(2, _instance.goalTransactionId);
    }
    if (_instance.realAmount) {
      _writer.writeDouble(3, _instance.realAmount);
    }
    if (_instance.totalFundings) {
      _writer.writeDouble(4, _instance.totalFundings);
    }
    if (_instance.totalCosts) {
      _writer.writeDouble(5, _instance.totalCosts);
    }
    if (_instance.sendEmail) {
      _writer.writeBool(6, _instance.sendEmail);
    }
  }

  private _transactionsByFunding?: TransactionsByFunding[];
  private _goalTransactionId: number;
  private _realAmount: number;
  private _totalFundings: number;
  private _totalCosts: number;
  private _sendEmail: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProcessTransactionManualRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ProcessTransactionManualRequest.AsObject>
  ) {
    _value = _value || {};
    this.transactionsByFunding = (_value.transactionsByFunding || []).map(
      m => new TransactionsByFunding(m)
    );
    this.goalTransactionId = _value.goalTransactionId;
    this.realAmount = _value.realAmount;
    this.totalFundings = _value.totalFundings;
    this.totalCosts = _value.totalCosts;
    this.sendEmail = _value.sendEmail;
    ProcessTransactionManualRequest.refineValues(this);
  }
  get transactionsByFunding(): TransactionsByFunding[] | undefined {
    return this._transactionsByFunding;
  }
  set transactionsByFunding(value: TransactionsByFunding[] | undefined) {
    this._transactionsByFunding = value;
  }
  get goalTransactionId(): number {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: number) {
    this._goalTransactionId = value;
  }
  get realAmount(): number {
    return this._realAmount;
  }
  set realAmount(value: number) {
    this._realAmount = value;
  }
  get totalFundings(): number {
    return this._totalFundings;
  }
  set totalFundings(value: number) {
    this._totalFundings = value;
  }
  get totalCosts(): number {
    return this._totalCosts;
  }
  set totalCosts(value: number) {
    this._totalCosts = value;
  }
  get sendEmail(): boolean {
    return this._sendEmail;
  }
  set sendEmail(value: boolean) {
    this._sendEmail = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProcessTransactionManualRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProcessTransactionManualRequest.AsObject {
    return {
      transactionsByFunding: (this.transactionsByFunding || []).map(m =>
        m.toObject()
      ),
      goalTransactionId: this.goalTransactionId,
      realAmount: this.realAmount,
      totalFundings: this.totalFundings,
      totalCosts: this.totalCosts,
      sendEmail: this.sendEmail
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ProcessTransactionManualRequest.AsProtobufJSON {
    return {
      transactionsByFunding: (this.transactionsByFunding || []).map(m =>
        m.toProtobufJSON(options)
      ),
      goalTransactionId: this.goalTransactionId,
      realAmount: this.realAmount,
      totalFundings: this.totalFundings,
      totalCosts: this.totalCosts,
      sendEmail: this.sendEmail
    };
  }
}
export module ProcessTransactionManualRequest {
  /**
   * Standard JavaScript object representation for ProcessTransactionManualRequest
   */
  export interface AsObject {
    transactionsByFunding?: TransactionsByFunding.AsObject[];
    goalTransactionId: number;
    realAmount: number;
    totalFundings: number;
    totalCosts: number;
    sendEmail: boolean;
  }

  /**
   * Protobuf JSON representation for ProcessTransactionManualRequest
   */
  export interface AsProtobufJSON {
    transactionsByFunding: TransactionsByFunding.AsProtobufJSON[] | null;
    goalTransactionId: number;
    realAmount: number;
    totalFundings: number;
    totalCosts: number;
    sendEmail: boolean;
  }
}

/**
 * Message implementation for goal_transaction.ProcessTransactionManualResponse
 */
export class ProcessTransactionManualResponse implements GrpcMessage {
  static id = 'goal_transaction.ProcessTransactionManualResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProcessTransactionManualResponse();
    ProcessTransactionManualResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProcessTransactionManualResponse) {
    _instance.ok = _instance.ok || false;
    _instance.warningsMsg = _instance.warningsMsg || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ProcessTransactionManualResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          (_instance.warningsMsg = _instance.warningsMsg || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ProcessTransactionManualResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ProcessTransactionManualResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.warningsMsg && _instance.warningsMsg.length) {
      _writer.writeRepeatedString(2, _instance.warningsMsg);
    }
  }

  private _ok: boolean;
  private _warningsMsg: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProcessTransactionManualResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ProcessTransactionManualResponse.AsObject>
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.warningsMsg = (_value.warningsMsg || []).slice();
    ProcessTransactionManualResponse.refineValues(this);
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }
  get warningsMsg(): string[] {
    return this._warningsMsg;
  }
  set warningsMsg(value: string[]) {
    this._warningsMsg = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProcessTransactionManualResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProcessTransactionManualResponse.AsObject {
    return {
      ok: this.ok,
      warningsMsg: (this.warningsMsg || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ProcessTransactionManualResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      warningsMsg: (this.warningsMsg || []).slice()
    };
  }
}
export module ProcessTransactionManualResponse {
  /**
   * Standard JavaScript object representation for ProcessTransactionManualResponse
   */
  export interface AsObject {
    ok: boolean;
    warningsMsg: string[];
  }

  /**
   * Protobuf JSON representation for ProcessTransactionManualResponse
   */
  export interface AsProtobufJSON {
    ok: boolean;
    warningsMsg: string[];
  }
}

/**
 * Message implementation for goal_transaction.TransactionsByFunding
 */
export class TransactionsByFunding implements GrpcMessage {
  static id = 'goal_transaction.TransactionsByFunding';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TransactionsByFunding();
    TransactionsByFunding.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TransactionsByFunding) {
    _instance.goalTransactionFunding =
      _instance.goalTransactionFunding || undefined;
    _instance.isValid = _instance.isValid || false;
    _instance.totalFunding = _instance.totalFunding || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TransactionsByFunding,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalTransactionFunding = new common_message008.GoalTransactionFundingModel();
          _reader.readMessage(
            _instance.goalTransactionFunding,
            common_message008.GoalTransactionFundingModel
              .deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.isValid = _reader.readBool();
          break;
        case 3:
          _instance.totalFunding = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    TransactionsByFunding.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TransactionsByFunding,
    _writer: BinaryWriter
  ) {
    if (_instance.goalTransactionFunding) {
      _writer.writeMessage(
        1,
        _instance.goalTransactionFunding as any,
        common_message008.GoalTransactionFundingModel.serializeBinaryToWriter
      );
    }
    if (_instance.isValid) {
      _writer.writeBool(2, _instance.isValid);
    }
    if (_instance.totalFunding) {
      _writer.writeDouble(3, _instance.totalFunding);
    }
  }

  private _goalTransactionFunding?: common_message008.GoalTransactionFundingModel;
  private _isValid: boolean;
  private _totalFunding: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TransactionsByFunding to deeply clone from
   */
  constructor(_value?: RecursivePartial<TransactionsByFunding.AsObject>) {
    _value = _value || {};
    this.goalTransactionFunding = _value.goalTransactionFunding
      ? new common_message008.GoalTransactionFundingModel(
          _value.goalTransactionFunding
        )
      : undefined;
    this.isValid = _value.isValid;
    this.totalFunding = _value.totalFunding;
    TransactionsByFunding.refineValues(this);
  }
  get goalTransactionFunding():
    | common_message008.GoalTransactionFundingModel
    | undefined {
    return this._goalTransactionFunding;
  }
  set goalTransactionFunding(
    value: common_message008.GoalTransactionFundingModel | undefined
  ) {
    this._goalTransactionFunding = value;
  }
  get isValid(): boolean {
    return this._isValid;
  }
  set isValid(value: boolean) {
    this._isValid = value;
  }
  get totalFunding(): number {
    return this._totalFunding;
  }
  set totalFunding(value: number) {
    this._totalFunding = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TransactionsByFunding.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TransactionsByFunding.AsObject {
    return {
      goalTransactionFunding: this.goalTransactionFunding
        ? this.goalTransactionFunding.toObject()
        : undefined,
      isValid: this.isValid,
      totalFunding: this.totalFunding
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TransactionsByFunding.AsProtobufJSON {
    return {
      goalTransactionFunding: this.goalTransactionFunding
        ? this.goalTransactionFunding.toProtobufJSON(options)
        : null,
      isValid: this.isValid,
      totalFunding: this.totalFunding
    };
  }
}
export module TransactionsByFunding {
  /**
   * Standard JavaScript object representation for TransactionsByFunding
   */
  export interface AsObject {
    goalTransactionFunding?: common_message008.GoalTransactionFundingModel.AsObject;
    isValid: boolean;
    totalFunding: number;
  }

  /**
   * Protobuf JSON representation for TransactionsByFunding
   */
  export interface AsProtobufJSON {
    goalTransactionFunding: common_message008.GoalTransactionFundingModel.AsProtobufJSON | null;
    isValid: boolean;
    totalFunding: number;
  }
}

/**
 * Message implementation for goal_transaction.AddGoalTransactionWithoutValidationResponse
 */
export class AddGoalTransactionWithoutValidationResponse
  implements GrpcMessage {
  static id = 'goal_transaction.AddGoalTransactionWithoutValidationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddGoalTransactionWithoutValidationResponse();
    AddGoalTransactionWithoutValidationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddGoalTransactionWithoutValidationResponse) {
    _instance.ok = _instance.ok || false;
    _instance.warningsMsg = _instance.warningsMsg || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddGoalTransactionWithoutValidationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        case 2:
          (_instance.warningsMsg = _instance.warningsMsg || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AddGoalTransactionWithoutValidationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddGoalTransactionWithoutValidationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
    if (_instance.warningsMsg && _instance.warningsMsg.length) {
      _writer.writeRepeatedString(2, _instance.warningsMsg);
    }
  }

  private _ok: boolean;
  private _warningsMsg: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddGoalTransactionWithoutValidationResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      AddGoalTransactionWithoutValidationResponse.AsObject
    >
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    this.warningsMsg = (_value.warningsMsg || []).slice();
    AddGoalTransactionWithoutValidationResponse.refineValues(this);
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }
  get warningsMsg(): string[] {
    return this._warningsMsg;
  }
  set warningsMsg(value: string[]) {
    this._warningsMsg = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddGoalTransactionWithoutValidationResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddGoalTransactionWithoutValidationResponse.AsObject {
    return {
      ok: this.ok,
      warningsMsg: (this.warningsMsg || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddGoalTransactionWithoutValidationResponse.AsProtobufJSON {
    return {
      ok: this.ok,
      warningsMsg: (this.warningsMsg || []).slice()
    };
  }
}
export module AddGoalTransactionWithoutValidationResponse {
  /**
   * Standard JavaScript object representation for AddGoalTransactionWithoutValidationResponse
   */
  export interface AsObject {
    ok: boolean;
    warningsMsg: string[];
  }

  /**
   * Protobuf JSON representation for AddGoalTransactionWithoutValidationResponse
   */
  export interface AsProtobufJSON {
    ok: boolean;
    warningsMsg: string[];
  }
}

/**
 * Message implementation for goal_transaction.ApprovalTransactionsDollarAccountFromIbkrRequest
 */
export class ApprovalTransactionsDollarAccountFromIbkrRequest
  implements GrpcMessage {
  static id =
    'goal_transaction.ApprovalTransactionsDollarAccountFromIbkrRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalTransactionsDollarAccountFromIbkrRequest();
    ApprovalTransactionsDollarAccountFromIbkrRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(
    _instance: ApprovalTransactionsDollarAccountFromIbkrRequest
  ) {
    _instance.goalTransactionIds = _instance.goalTransactionIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalTransactionsDollarAccountFromIbkrRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.goalTransactionIds =
            _instance.goalTransactionIds || []).push(
            ...(_reader.readPackedInt32() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalTransactionsDollarAccountFromIbkrRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalTransactionsDollarAccountFromIbkrRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalTransactionIds && _instance.goalTransactionIds.length) {
      _writer.writePackedInt32(1, _instance.goalTransactionIds);
    }
  }

  private _goalTransactionIds: number[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalTransactionsDollarAccountFromIbkrRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      ApprovalTransactionsDollarAccountFromIbkrRequest.AsObject
    >
  ) {
    _value = _value || {};
    this.goalTransactionIds = (_value.goalTransactionIds || []).slice();
    ApprovalTransactionsDollarAccountFromIbkrRequest.refineValues(this);
  }
  get goalTransactionIds(): number[] {
    return this._goalTransactionIds;
  }
  set goalTransactionIds(value: number[]) {
    this._goalTransactionIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalTransactionsDollarAccountFromIbkrRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalTransactionsDollarAccountFromIbkrRequest.AsObject {
    return {
      goalTransactionIds: (this.goalTransactionIds || []).slice()
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalTransactionsDollarAccountFromIbkrRequest.AsProtobufJSON {
    return {
      goalTransactionIds: (this.goalTransactionIds || []).slice()
    };
  }
}
export module ApprovalTransactionsDollarAccountFromIbkrRequest {
  /**
   * Standard JavaScript object representation for ApprovalTransactionsDollarAccountFromIbkrRequest
   */
  export interface AsObject {
    goalTransactionIds: number[];
  }

  /**
   * Protobuf JSON representation for ApprovalTransactionsDollarAccountFromIbkrRequest
   */
  export interface AsProtobufJSON {
    goalTransactionIds: number[];
  }
}

/**
 * Message implementation for goal_transaction.ApprovalTransactionsDollarAccountFromIbkrResponse
 */
export class ApprovalTransactionsDollarAccountFromIbkrResponse
  implements GrpcMessage {
  static id =
    'goal_transaction.ApprovalTransactionsDollarAccountFromIbkrResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalTransactionsDollarAccountFromIbkrResponse();
    ApprovalTransactionsDollarAccountFromIbkrResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(
    _instance: ApprovalTransactionsDollarAccountFromIbkrResponse
  ) {
    _instance.ok = _instance.ok || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalTransactionsDollarAccountFromIbkrResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.ok = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalTransactionsDollarAccountFromIbkrResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalTransactionsDollarAccountFromIbkrResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.ok) {
      _writer.writeBool(1, _instance.ok);
    }
  }

  private _ok: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalTransactionsDollarAccountFromIbkrResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<
      ApprovalTransactionsDollarAccountFromIbkrResponse.AsObject
    >
  ) {
    _value = _value || {};
    this.ok = _value.ok;
    ApprovalTransactionsDollarAccountFromIbkrResponse.refineValues(this);
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalTransactionsDollarAccountFromIbkrResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalTransactionsDollarAccountFromIbkrResponse.AsObject {
    return {
      ok: this.ok
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalTransactionsDollarAccountFromIbkrResponse.AsProtobufJSON {
    return {
      ok: this.ok
    };
  }
}
export module ApprovalTransactionsDollarAccountFromIbkrResponse {
  /**
   * Standard JavaScript object representation for ApprovalTransactionsDollarAccountFromIbkrResponse
   */
  export interface AsObject {
    ok: boolean;
  }

  /**
   * Protobuf JSON representation for ApprovalTransactionsDollarAccountFromIbkrResponse
   */
  export interface AsProtobufJSON {
    ok: boolean;
  }
}

/**
 * Message implementation for goal_transaction.ChangeStateGoalTransactionRequest
 */
export class ChangeStateGoalTransactionRequest implements GrpcMessage {
  static id = 'goal_transaction.ChangeStateGoalTransactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ChangeStateGoalTransactionRequest();
    ChangeStateGoalTransactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ChangeStateGoalTransactionRequest) {
    _instance.goalTransactionId = _instance.goalTransactionId || 0;
    _instance.state = _instance.state || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ChangeStateGoalTransactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalTransactionId = _reader.readInt32();
          break;
        case 2:
          _instance.state = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    ChangeStateGoalTransactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ChangeStateGoalTransactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.goalTransactionId) {
      _writer.writeInt32(1, _instance.goalTransactionId);
    }
    if (_instance.state) {
      _writer.writeEnum(2, _instance.state);
    }
  }

  private _goalTransactionId: number;
  private _state: goal_transaction_enums002.GoalTransactionState;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ChangeStateGoalTransactionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ChangeStateGoalTransactionRequest.AsObject>
  ) {
    _value = _value || {};
    this.goalTransactionId = _value.goalTransactionId;
    this.state = _value.state;
    ChangeStateGoalTransactionRequest.refineValues(this);
  }
  get goalTransactionId(): number {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: number) {
    this._goalTransactionId = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ChangeStateGoalTransactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ChangeStateGoalTransactionRequest.AsObject {
    return {
      goalTransactionId: this.goalTransactionId,
      state: this.state
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ChangeStateGoalTransactionRequest.AsProtobufJSON {
    return {
      goalTransactionId: this.goalTransactionId,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ]
    };
  }
}
export module ChangeStateGoalTransactionRequest {
  /**
   * Standard JavaScript object representation for ChangeStateGoalTransactionRequest
   */
  export interface AsObject {
    goalTransactionId: number;
    state: goal_transaction_enums002.GoalTransactionState;
  }

  /**
   * Protobuf JSON representation for ChangeStateGoalTransactionRequest
   */
  export interface AsProtobufJSON {
    goalTransactionId: number;
    state: string;
  }
}

/**
 * Message implementation for goal_transaction.ChangeStateGoalTransactionResponse
 */
export class ChangeStateGoalTransactionResponse implements GrpcMessage {
  static id = 'goal_transaction.ChangeStateGoalTransactionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ChangeStateGoalTransactionResponse();
    ChangeStateGoalTransactionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ChangeStateGoalTransactionResponse) {
    _instance.goalTransactionId = _instance.goalTransactionId || 0;
    _instance.state = _instance.state || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ChangeStateGoalTransactionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalTransactionId = _reader.readInt32();
          break;
        case 2:
          _instance.state = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    ChangeStateGoalTransactionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ChangeStateGoalTransactionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.goalTransactionId) {
      _writer.writeInt32(1, _instance.goalTransactionId);
    }
    if (_instance.state) {
      _writer.writeEnum(2, _instance.state);
    }
  }

  private _goalTransactionId: number;
  private _state: goal_transaction_enums002.GoalTransactionState;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ChangeStateGoalTransactionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ChangeStateGoalTransactionResponse.AsObject>
  ) {
    _value = _value || {};
    this.goalTransactionId = _value.goalTransactionId;
    this.state = _value.state;
    ChangeStateGoalTransactionResponse.refineValues(this);
  }
  get goalTransactionId(): number {
    return this._goalTransactionId;
  }
  set goalTransactionId(value: number) {
    this._goalTransactionId = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ChangeStateGoalTransactionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ChangeStateGoalTransactionResponse.AsObject {
    return {
      goalTransactionId: this.goalTransactionId,
      state: this.state
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ChangeStateGoalTransactionResponse.AsProtobufJSON {
    return {
      goalTransactionId: this.goalTransactionId,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ]
    };
  }
}
export module ChangeStateGoalTransactionResponse {
  /**
   * Standard JavaScript object representation for ChangeStateGoalTransactionResponse
   */
  export interface AsObject {
    goalTransactionId: number;
    state: goal_transaction_enums002.GoalTransactionState;
  }

  /**
   * Protobuf JSON representation for ChangeStateGoalTransactionResponse
   */
  export interface AsProtobufJSON {
    goalTransactionId: number;
    state: string;
  }
}

/**
 * Message implementation for goal_transaction.ProcessSaleTransactionRequest
 */
export class ProcessSaleTransactionRequest implements GrpcMessage {
  static id = 'goal_transaction.ProcessSaleTransactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProcessSaleTransactionRequest();
    ProcessSaleTransactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProcessSaleTransactionRequest) {
    _instance.date = _instance.date || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ProcessSaleTransactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ProcessSaleTransactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ProcessSaleTransactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.date) {
      _writer.writeMessage(
        1,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _date?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProcessSaleTransactionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ProcessSaleTransactionRequest.AsObject>
  ) {
    _value = _value || {};
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    ProcessSaleTransactionRequest.refineValues(this);
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProcessSaleTransactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProcessSaleTransactionRequest.AsObject {
    return {
      date: this.date ? this.date.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ProcessSaleTransactionRequest.AsProtobufJSON {
    return {
      date: this.date ? this.date.toProtobufJSON(options) : null
    };
  }
}
export module ProcessSaleTransactionRequest {
  /**
   * Standard JavaScript object representation for ProcessSaleTransactionRequest
   */
  export interface AsObject {
    date?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for ProcessSaleTransactionRequest
   */
  export interface AsProtobufJSON {
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for goal_transaction.ListVectorRequest
 */
export class ListVectorRequest implements GrpcMessage {
  static id = 'goal_transaction.ListVectorRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListVectorRequest();
    ListVectorRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListVectorRequest) {
    _instance.baseRequest = _instance.baseRequest || undefined;
    _instance.start = _instance.start || undefined;
    _instance.end = _instance.end || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListVectorRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.baseRequest = new common_message008.BaseListRequest();
          _reader.readMessage(
            _instance.baseRequest,
            common_message008.BaseListRequest.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.start = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.start,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.end = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.end,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListVectorRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListVectorRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.baseRequest) {
      _writer.writeMessage(
        1,
        _instance.baseRequest as any,
        common_message008.BaseListRequest.serializeBinaryToWriter
      );
    }
    if (_instance.start) {
      _writer.writeMessage(
        2,
        _instance.start as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.end) {
      _writer.writeMessage(
        3,
        _instance.end as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _baseRequest?: common_message008.BaseListRequest;
  private _start?: googleProtobuf001.Timestamp;
  private _end?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListVectorRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListVectorRequest.AsObject>) {
    _value = _value || {};
    this.baseRequest = _value.baseRequest
      ? new common_message008.BaseListRequest(_value.baseRequest)
      : undefined;
    this.start = _value.start
      ? new googleProtobuf001.Timestamp(_value.start)
      : undefined;
    this.end = _value.end
      ? new googleProtobuf001.Timestamp(_value.end)
      : undefined;
    ListVectorRequest.refineValues(this);
  }
  get baseRequest(): common_message008.BaseListRequest | undefined {
    return this._baseRequest;
  }
  set baseRequest(value: common_message008.BaseListRequest | undefined) {
    this._baseRequest = value;
  }
  get start(): googleProtobuf001.Timestamp | undefined {
    return this._start;
  }
  set start(value: googleProtobuf001.Timestamp | undefined) {
    this._start = value;
  }
  get end(): googleProtobuf001.Timestamp | undefined {
    return this._end;
  }
  set end(value: googleProtobuf001.Timestamp | undefined) {
    this._end = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListVectorRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListVectorRequest.AsObject {
    return {
      baseRequest: this.baseRequest ? this.baseRequest.toObject() : undefined,
      start: this.start ? this.start.toObject() : undefined,
      end: this.end ? this.end.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListVectorRequest.AsProtobufJSON {
    return {
      baseRequest: this.baseRequest
        ? this.baseRequest.toProtobufJSON(options)
        : null,
      start: this.start ? this.start.toProtobufJSON(options) : null,
      end: this.end ? this.end.toProtobufJSON(options) : null
    };
  }
}
export module ListVectorRequest {
  /**
   * Standard JavaScript object representation for ListVectorRequest
   */
  export interface AsObject {
    baseRequest?: common_message008.BaseListRequest.AsObject;
    start?: googleProtobuf001.Timestamp.AsObject;
    end?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for ListVectorRequest
   */
  export interface AsProtobufJSON {
    baseRequest: common_message008.BaseListRequest.AsProtobufJSON | null;
    start: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    end: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for goal_transaction.ListGoalTransactionSummaryResponse
 */
export class ListGoalTransactionSummaryResponse implements GrpcMessage {
  static id = 'goal_transaction.ListGoalTransactionSummaryResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalTransactionSummaryResponse();
    ListGoalTransactionSummaryResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalTransactionSummaryResponse) {
    _instance.totalAmountClp = _instance.totalAmountClp || 0;
    _instance.totalAmountUsd = _instance.totalAmountUsd || 0;
    _instance.totalBuyClp = _instance.totalBuyClp || 0;
    _instance.totalBuyUsd = _instance.totalBuyUsd || 0;
    _instance.totalSaleClp = _instance.totalSaleClp || 0;
    _instance.totalSaleUsd = _instance.totalSaleUsd || 0;
    _instance.totalPricePond = _instance.totalPricePond || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalTransactionSummaryResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.totalAmountClp = _reader.readDouble();
          break;
        case 2:
          _instance.totalAmountUsd = _reader.readDouble();
          break;
        case 3:
          _instance.totalBuyClp = _reader.readDouble();
          break;
        case 4:
          _instance.totalBuyUsd = _reader.readDouble();
          break;
        case 5:
          _instance.totalSaleClp = _reader.readDouble();
          break;
        case 6:
          _instance.totalSaleUsd = _reader.readDouble();
          break;
        case 7:
          _instance.totalPricePond = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalTransactionSummaryResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalTransactionSummaryResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.totalAmountClp) {
      _writer.writeDouble(1, _instance.totalAmountClp);
    }
    if (_instance.totalAmountUsd) {
      _writer.writeDouble(2, _instance.totalAmountUsd);
    }
    if (_instance.totalBuyClp) {
      _writer.writeDouble(3, _instance.totalBuyClp);
    }
    if (_instance.totalBuyUsd) {
      _writer.writeDouble(4, _instance.totalBuyUsd);
    }
    if (_instance.totalSaleClp) {
      _writer.writeDouble(5, _instance.totalSaleClp);
    }
    if (_instance.totalSaleUsd) {
      _writer.writeDouble(6, _instance.totalSaleUsd);
    }
    if (_instance.totalPricePond) {
      _writer.writeDouble(7, _instance.totalPricePond);
    }
  }

  private _totalAmountClp: number;
  private _totalAmountUsd: number;
  private _totalBuyClp: number;
  private _totalBuyUsd: number;
  private _totalSaleClp: number;
  private _totalSaleUsd: number;
  private _totalPricePond: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalTransactionSummaryResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListGoalTransactionSummaryResponse.AsObject>
  ) {
    _value = _value || {};
    this.totalAmountClp = _value.totalAmountClp;
    this.totalAmountUsd = _value.totalAmountUsd;
    this.totalBuyClp = _value.totalBuyClp;
    this.totalBuyUsd = _value.totalBuyUsd;
    this.totalSaleClp = _value.totalSaleClp;
    this.totalSaleUsd = _value.totalSaleUsd;
    this.totalPricePond = _value.totalPricePond;
    ListGoalTransactionSummaryResponse.refineValues(this);
  }
  get totalAmountClp(): number {
    return this._totalAmountClp;
  }
  set totalAmountClp(value: number) {
    this._totalAmountClp = value;
  }
  get totalAmountUsd(): number {
    return this._totalAmountUsd;
  }
  set totalAmountUsd(value: number) {
    this._totalAmountUsd = value;
  }
  get totalBuyClp(): number {
    return this._totalBuyClp;
  }
  set totalBuyClp(value: number) {
    this._totalBuyClp = value;
  }
  get totalBuyUsd(): number {
    return this._totalBuyUsd;
  }
  set totalBuyUsd(value: number) {
    this._totalBuyUsd = value;
  }
  get totalSaleClp(): number {
    return this._totalSaleClp;
  }
  set totalSaleClp(value: number) {
    this._totalSaleClp = value;
  }
  get totalSaleUsd(): number {
    return this._totalSaleUsd;
  }
  set totalSaleUsd(value: number) {
    this._totalSaleUsd = value;
  }
  get totalPricePond(): number {
    return this._totalPricePond;
  }
  set totalPricePond(value: number) {
    this._totalPricePond = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalTransactionSummaryResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalTransactionSummaryResponse.AsObject {
    return {
      totalAmountClp: this.totalAmountClp,
      totalAmountUsd: this.totalAmountUsd,
      totalBuyClp: this.totalBuyClp,
      totalBuyUsd: this.totalBuyUsd,
      totalSaleClp: this.totalSaleClp,
      totalSaleUsd: this.totalSaleUsd,
      totalPricePond: this.totalPricePond
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalTransactionSummaryResponse.AsProtobufJSON {
    return {
      totalAmountClp: this.totalAmountClp,
      totalAmountUsd: this.totalAmountUsd,
      totalBuyClp: this.totalBuyClp,
      totalBuyUsd: this.totalBuyUsd,
      totalSaleClp: this.totalSaleClp,
      totalSaleUsd: this.totalSaleUsd,
      totalPricePond: this.totalPricePond
    };
  }
}
export module ListGoalTransactionSummaryResponse {
  /**
   * Standard JavaScript object representation for ListGoalTransactionSummaryResponse
   */
  export interface AsObject {
    totalAmountClp: number;
    totalAmountUsd: number;
    totalBuyClp: number;
    totalBuyUsd: number;
    totalSaleClp: number;
    totalSaleUsd: number;
    totalPricePond: number;
  }

  /**
   * Protobuf JSON representation for ListGoalTransactionSummaryResponse
   */
  export interface AsProtobufJSON {
    totalAmountClp: number;
    totalAmountUsd: number;
    totalBuyClp: number;
    totalBuyUsd: number;
    totalSaleClp: number;
    totalSaleUsd: number;
    totalPricePond: number;
  }
}

/**
 * Message implementation for goal_transaction.UpdateByIdAmountToProcessRequest
 */
export class UpdateByIdAmountToProcessRequest implements GrpcMessage {
  static id = 'goal_transaction.UpdateByIdAmountToProcessRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateByIdAmountToProcessRequest();
    UpdateByIdAmountToProcessRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateByIdAmountToProcessRequest) {
    _instance.id = _instance.id || 0;
    _instance.amountToProcess = _instance.amountToProcess || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateByIdAmountToProcessRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.amountToProcess = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateByIdAmountToProcessRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateByIdAmountToProcessRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.amountToProcess) {
      _writer.writeDouble(2, _instance.amountToProcess);
    }
  }

  private _id: number;
  private _amountToProcess: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateByIdAmountToProcessRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UpdateByIdAmountToProcessRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.amountToProcess = _value.amountToProcess;
    UpdateByIdAmountToProcessRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get amountToProcess(): number {
    return this._amountToProcess;
  }
  set amountToProcess(value: number) {
    this._amountToProcess = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateByIdAmountToProcessRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateByIdAmountToProcessRequest.AsObject {
    return {
      id: this.id,
      amountToProcess: this.amountToProcess
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateByIdAmountToProcessRequest.AsProtobufJSON {
    return {
      id: this.id,
      amountToProcess: this.amountToProcess
    };
  }
}
export module UpdateByIdAmountToProcessRequest {
  /**
   * Standard JavaScript object representation for UpdateByIdAmountToProcessRequest
   */
  export interface AsObject {
    id: number;
    amountToProcess: number;
  }

  /**
   * Protobuf JSON representation for UpdateByIdAmountToProcessRequest
   */
  export interface AsProtobufJSON {
    id: number;
    amountToProcess: number;
  }
}

/**
 * Message implementation for goal_transaction.RequestGetSingleGoalTransaction
 */
export class RequestGetSingleGoalTransaction implements GrpcMessage {
  static id = 'goal_transaction.RequestGetSingleGoalTransaction';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestGetSingleGoalTransaction();
    RequestGetSingleGoalTransaction.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestGetSingleGoalTransaction) {
    _instance.id = _instance.id || undefined;
    _instance.identifier = _instance.identifier || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestGetSingleGoalTransaction,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.id,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.identifier = new googleProtobuf000.StringValue();
          _reader.readMessage(
            _instance.identifier,
            googleProtobuf000.StringValue.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RequestGetSingleGoalTransaction.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestGetSingleGoalTransaction,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeMessage(
        1,
        _instance.id as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
    if (_instance.identifier) {
      _writer.writeMessage(
        2,
        _instance.identifier as any,
        googleProtobuf000.StringValue.serializeBinaryToWriter
      );
    }
  }

  private _id?: googleProtobuf000.Int32Value;
  private _identifier?: googleProtobuf000.StringValue;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestGetSingleGoalTransaction to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<RequestGetSingleGoalTransaction.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id
      ? new googleProtobuf000.Int32Value(_value.id)
      : undefined;
    this.identifier = _value.identifier
      ? new googleProtobuf000.StringValue(_value.identifier)
      : undefined;
    RequestGetSingleGoalTransaction.refineValues(this);
  }
  get id(): googleProtobuf000.Int32Value | undefined {
    return this._id;
  }
  set id(value: googleProtobuf000.Int32Value | undefined) {
    this._id = value;
  }
  get identifier(): googleProtobuf000.StringValue | undefined {
    return this._identifier;
  }
  set identifier(value: googleProtobuf000.StringValue | undefined) {
    this._identifier = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestGetSingleGoalTransaction.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestGetSingleGoalTransaction.AsObject {
    return {
      id: this.id ? this.id.toObject() : undefined,
      identifier: this.identifier ? this.identifier.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestGetSingleGoalTransaction.AsProtobufJSON {
    return {
      id: this.id ? this.id.toProtobufJSON(options) : null,
      identifier: this.identifier
        ? this.identifier.toProtobufJSON(options)
        : null
    };
  }
}
export module RequestGetSingleGoalTransaction {
  /**
   * Standard JavaScript object representation for RequestGetSingleGoalTransaction
   */
  export interface AsObject {
    id?: googleProtobuf000.Int32Value.AsObject;
    identifier?: googleProtobuf000.StringValue.AsObject;
  }

  /**
   * Protobuf JSON representation for RequestGetSingleGoalTransaction
   */
  export interface AsProtobufJSON {
    id: googleProtobuf000.Int32Value.AsProtobufJSON | null;
    identifier: googleProtobuf000.StringValue.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for goal_transaction.RequestGetGetTransactionsInProcess
 */
export class RequestGetGetTransactionsInProcess implements GrpcMessage {
  static id = 'goal_transaction.RequestGetGetTransactionsInProcess';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestGetGetTransactionsInProcess();
    RequestGetGetTransactionsInProcess.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestGetGetTransactionsInProcess) {
    _instance.goalId = _instance.goalId || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestGetGetTransactionsInProcess,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.goalId = new googleProtobuf000.Int32Value();
          _reader.readMessage(
            _instance.goalId,
            googleProtobuf000.Int32Value.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RequestGetGetTransactionsInProcess.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestGetGetTransactionsInProcess,
    _writer: BinaryWriter
  ) {
    if (_instance.goalId) {
      _writer.writeMessage(
        1,
        _instance.goalId as any,
        googleProtobuf000.Int32Value.serializeBinaryToWriter
      );
    }
  }

  private _goalId?: googleProtobuf000.Int32Value;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestGetGetTransactionsInProcess to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<RequestGetGetTransactionsInProcess.AsObject>
  ) {
    _value = _value || {};
    this.goalId = _value.goalId
      ? new googleProtobuf000.Int32Value(_value.goalId)
      : undefined;
    RequestGetGetTransactionsInProcess.refineValues(this);
  }
  get goalId(): googleProtobuf000.Int32Value | undefined {
    return this._goalId;
  }
  set goalId(value: googleProtobuf000.Int32Value | undefined) {
    this._goalId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestGetGetTransactionsInProcess.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestGetGetTransactionsInProcess.AsObject {
    return {
      goalId: this.goalId ? this.goalId.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestGetGetTransactionsInProcess.AsProtobufJSON {
    return {
      goalId: this.goalId ? this.goalId.toProtobufJSON(options) : null
    };
  }
}
export module RequestGetGetTransactionsInProcess {
  /**
   * Standard JavaScript object representation for RequestGetGetTransactionsInProcess
   */
  export interface AsObject {
    goalId?: googleProtobuf000.Int32Value.AsObject;
  }

  /**
   * Protobuf JSON representation for RequestGetGetTransactionsInProcess
   */
  export interface AsProtobufJSON {
    goalId: googleProtobuf000.Int32Value.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for goal_transaction.ResponseGetGetTransactionsInProcess
 */
export class ResponseGetGetTransactionsInProcess implements GrpcMessage {
  static id = 'goal_transaction.ResponseGetGetTransactionsInProcess';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ResponseGetGetTransactionsInProcess();
    ResponseGetGetTransactionsInProcess.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ResponseGetGetTransactionsInProcess) {
    _instance.value = _instance.value || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ResponseGetGetTransactionsInProcess,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.GoalTransactionModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.GoalTransactionModel.deserializeBinaryFromReader
          );
          (_instance.value = _instance.value || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    ResponseGetGetTransactionsInProcess.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ResponseGetGetTransactionsInProcess,
    _writer: BinaryWriter
  ) {
    if (_instance.value && _instance.value.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.value as any,
        common_message008.GoalTransactionModel.serializeBinaryToWriter
      );
    }
  }

  private _value?: common_message008.GoalTransactionModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ResponseGetGetTransactionsInProcess to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ResponseGetGetTransactionsInProcess.AsObject>
  ) {
    _value = _value || {};
    this.value = (_value.value || []).map(
      m => new common_message008.GoalTransactionModel(m)
    );
    ResponseGetGetTransactionsInProcess.refineValues(this);
  }
  get value(): common_message008.GoalTransactionModel[] | undefined {
    return this._value;
  }
  set value(value: common_message008.GoalTransactionModel[] | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ResponseGetGetTransactionsInProcess.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ResponseGetGetTransactionsInProcess.AsObject {
    return {
      value: (this.value || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ResponseGetGetTransactionsInProcess.AsProtobufJSON {
    return {
      value: (this.value || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ResponseGetGetTransactionsInProcess {
  /**
   * Standard JavaScript object representation for ResponseGetGetTransactionsInProcess
   */
  export interface AsObject {
    value?: common_message008.GoalTransactionModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for ResponseGetGetTransactionsInProcess
   */
  export interface AsProtobufJSON {
    value: common_message008.GoalTransactionModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for goal_transaction.ProcessVectorTransactionsFileResponse
 */
export class ProcessVectorTransactionsFileResponse implements GrpcMessage {
  static id = 'goal_transaction.ProcessVectorTransactionsFileResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProcessVectorTransactionsFileResponse();
    ProcessVectorTransactionsFileResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProcessVectorTransactionsFileResponse) {
    _instance.status = _instance.status || '';
    _instance.transactionProccesingId = _instance.transactionProccesingId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ProcessVectorTransactionsFileResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.status = _reader.readString();
          break;
        case 2:
          _instance.transactionProccesingId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ProcessVectorTransactionsFileResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ProcessVectorTransactionsFileResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.status) {
      _writer.writeString(1, _instance.status);
    }
    if (_instance.transactionProccesingId) {
      _writer.writeInt32(2, _instance.transactionProccesingId);
    }
  }

  private _status: string;
  private _transactionProccesingId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProcessVectorTransactionsFileResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ProcessVectorTransactionsFileResponse.AsObject>
  ) {
    _value = _value || {};
    this.status = _value.status;
    this.transactionProccesingId = _value.transactionProccesingId;
    ProcessVectorTransactionsFileResponse.refineValues(this);
  }
  get status(): string {
    return this._status;
  }
  set status(value: string) {
    this._status = value;
  }
  get transactionProccesingId(): number {
    return this._transactionProccesingId;
  }
  set transactionProccesingId(value: number) {
    this._transactionProccesingId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProcessVectorTransactionsFileResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProcessVectorTransactionsFileResponse.AsObject {
    return {
      status: this.status,
      transactionProccesingId: this.transactionProccesingId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ProcessVectorTransactionsFileResponse.AsProtobufJSON {
    return {
      status: this.status,
      transactionProccesingId: this.transactionProccesingId
    };
  }
}
export module ProcessVectorTransactionsFileResponse {
  /**
   * Standard JavaScript object representation for ProcessVectorTransactionsFileResponse
   */
  export interface AsObject {
    status: string;
    transactionProccesingId: number;
  }

  /**
   * Protobuf JSON representation for ProcessVectorTransactionsFileResponse
   */
  export interface AsProtobufJSON {
    status: string;
    transactionProccesingId: number;
  }
}

/**
 * Message implementation for goal_transaction.CalculateGoalTransactionFundingRequest
 */
export class CalculateGoalTransactionFundingRequest implements GrpcMessage {
  static id = 'goal_transaction.CalculateGoalTransactionFundingRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculateGoalTransactionFundingRequest();
    CalculateGoalTransactionFundingRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculateGoalTransactionFundingRequest) {
    _instance.date = _instance.date || undefined;
    _instance.goalId = _instance.goalId || 0;
    _instance.amount = _instance.amount || 0;
    _instance.type = _instance.type || 0;
    _instance.state = _instance.state || 0;
    _instance.currencyId = _instance.currencyId || 0;
    _instance.all = _instance.all || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculateGoalTransactionFundingRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.goalId = _reader.readInt32();
          break;
        case 4:
          _instance.amount = _reader.readDouble();
          break;
        case 5:
          _instance.type = _reader.readEnum();
          break;
        case 6:
          _instance.state = _reader.readEnum();
          break;
        case 7:
          _instance.currencyId = _reader.readInt32();
          break;
        case 8:
          _instance.all = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CalculateGoalTransactionFundingRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculateGoalTransactionFundingRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.date) {
      _writer.writeMessage(
        1,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.goalId) {
      _writer.writeInt32(3, _instance.goalId);
    }
    if (_instance.amount) {
      _writer.writeDouble(4, _instance.amount);
    }
    if (_instance.type) {
      _writer.writeEnum(5, _instance.type);
    }
    if (_instance.state) {
      _writer.writeEnum(6, _instance.state);
    }
    if (_instance.currencyId) {
      _writer.writeInt32(7, _instance.currencyId);
    }
    if (_instance.all) {
      _writer.writeBool(8, _instance.all);
    }
  }

  private _date?: googleProtobuf001.Timestamp;
  private _goalId: number;
  private _amount: number;
  private _type: goal_transaction_type_enums003.GoalTransactionType;
  private _state: goal_transaction_enums002.GoalTransactionState;
  private _currencyId: number;
  private _all: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculateGoalTransactionFundingRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CalculateGoalTransactionFundingRequest.AsObject>
  ) {
    _value = _value || {};
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.goalId = _value.goalId;
    this.amount = _value.amount;
    this.type = _value.type;
    this.state = _value.state;
    this.currencyId = _value.currencyId;
    this.all = _value.all;
    CalculateGoalTransactionFundingRequest.refineValues(this);
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get goalId(): number {
    return this._goalId;
  }
  set goalId(value: number) {
    this._goalId = value;
  }
  get amount(): number {
    return this._amount;
  }
  set amount(value: number) {
    this._amount = value;
  }
  get type(): goal_transaction_type_enums003.GoalTransactionType {
    return this._type;
  }
  set type(value: goal_transaction_type_enums003.GoalTransactionType) {
    this._type = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get all(): boolean {
    return this._all;
  }
  set all(value: boolean) {
    this._all = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculateGoalTransactionFundingRequest.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculateGoalTransactionFundingRequest.AsObject {
    return {
      date: this.date ? this.date.toObject() : undefined,
      goalId: this.goalId,
      amount: this.amount,
      type: this.type,
      state: this.state,
      currencyId: this.currencyId,
      all: this.all
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculateGoalTransactionFundingRequest.AsProtobufJSON {
    return {
      date: this.date ? this.date.toProtobufJSON(options) : null,
      goalId: this.goalId,
      amount: this.amount,
      type:
        goal_transaction_type_enums003.GoalTransactionType[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      currencyId: this.currencyId,
      all: this.all
    };
  }
}
export module CalculateGoalTransactionFundingRequest {
  /**
   * Standard JavaScript object representation for CalculateGoalTransactionFundingRequest
   */
  export interface AsObject {
    date?: googleProtobuf001.Timestamp.AsObject;
    goalId: number;
    amount: number;
    type: goal_transaction_type_enums003.GoalTransactionType;
    state: goal_transaction_enums002.GoalTransactionState;
    currencyId: number;
    all: boolean;
  }

  /**
   * Protobuf JSON representation for CalculateGoalTransactionFundingRequest
   */
  export interface AsProtobufJSON {
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    goalId: number;
    amount: number;
    type: string;
    state: string;
    currencyId: number;
    all: boolean;
  }
}

/**
 * Message implementation for goal_transaction.CalculateGoalTransactionFundingResponse
 */
export class CalculateGoalTransactionFundingResponse implements GrpcMessage {
  static id = 'goal_transaction.CalculateGoalTransactionFundingResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CalculateGoalTransactionFundingResponse();
    CalculateGoalTransactionFundingResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CalculateGoalTransactionFundingResponse) {
    _instance.value = _instance.value || [];
    _instance.date = _instance.date || undefined;
    _instance.state = _instance.state || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CalculateGoalTransactionFundingResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.GoalTransactionModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.GoalTransactionModel.deserializeBinaryFromReader
          );
          (_instance.value = _instance.value || []).push(messageInitializer1);
          break;
        case 2:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.state = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    CalculateGoalTransactionFundingResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CalculateGoalTransactionFundingResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.value && _instance.value.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.value as any,
        common_message008.GoalTransactionModel.serializeBinaryToWriter
      );
    }
    if (_instance.date) {
      _writer.writeMessage(
        2,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.state) {
      _writer.writeEnum(3, _instance.state);
    }
  }

  private _value?: common_message008.GoalTransactionModel[];
  private _date?: googleProtobuf001.Timestamp;
  private _state: goal_transaction_enums002.GoalTransactionState;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CalculateGoalTransactionFundingResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CalculateGoalTransactionFundingResponse.AsObject>
  ) {
    _value = _value || {};
    this.value = (_value.value || []).map(
      m => new common_message008.GoalTransactionModel(m)
    );
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.state = _value.state;
    CalculateGoalTransactionFundingResponse.refineValues(this);
  }
  get value(): common_message008.GoalTransactionModel[] | undefined {
    return this._value;
  }
  set value(value: common_message008.GoalTransactionModel[] | undefined) {
    this._value = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get state(): goal_transaction_enums002.GoalTransactionState {
    return this._state;
  }
  set state(value: goal_transaction_enums002.GoalTransactionState) {
    this._state = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CalculateGoalTransactionFundingResponse.serializeBinaryToWriter(
      this,
      writer
    );
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CalculateGoalTransactionFundingResponse.AsObject {
    return {
      value: (this.value || []).map(m => m.toObject()),
      date: this.date ? this.date.toObject() : undefined,
      state: this.state
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CalculateGoalTransactionFundingResponse.AsProtobufJSON {
    return {
      value: (this.value || []).map(m => m.toProtobufJSON(options)),
      date: this.date ? this.date.toProtobufJSON(options) : null,
      state:
        goal_transaction_enums002.GoalTransactionState[
          this.state === null || this.state === undefined ? 0 : this.state
        ]
    };
  }
}
export module CalculateGoalTransactionFundingResponse {
  /**
   * Standard JavaScript object representation for CalculateGoalTransactionFundingResponse
   */
  export interface AsObject {
    value?: common_message008.GoalTransactionModel.AsObject[];
    date?: googleProtobuf001.Timestamp.AsObject;
    state: goal_transaction_enums002.GoalTransactionState;
  }

  /**
   * Protobuf JSON representation for CalculateGoalTransactionFundingResponse
   */
  export interface AsProtobufJSON {
    value: common_message008.GoalTransactionModel.AsProtobufJSON[] | null;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    state: string;
  }
}

/**
 * Message implementation for goal_transaction.RemoveGoalTransactionRequest
 */
export class RemoveGoalTransactionRequest implements GrpcMessage {
  static id = 'goal_transaction.RemoveGoalTransactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemoveGoalTransactionRequest();
    RemoveGoalTransactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemoveGoalTransactionRequest) {
    _instance.id = _instance.id || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemoveGoalTransactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    RemoveGoalTransactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemoveGoalTransactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
  }

  private _id: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemoveGoalTransactionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<RemoveGoalTransactionRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    RemoveGoalTransactionRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemoveGoalTransactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemoveGoalTransactionRequest.AsObject {
    return {
      id: this.id
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemoveGoalTransactionRequest.AsProtobufJSON {
    return {
      id: this.id
    };
  }
}
export module RemoveGoalTransactionRequest {
  /**
   * Standard JavaScript object representation for RemoveGoalTransactionRequest
   */
  export interface AsObject {
    id: number;
  }

  /**
   * Protobuf JSON representation for RemoveGoalTransactionRequest
   */
  export interface AsProtobufJSON {
    id: number;
  }
}

/**
 * Message implementation for goal_transaction.RemoveGoalTransactionResponse
 */
export class RemoveGoalTransactionResponse implements GrpcMessage {
  static id = 'goal_transaction.RemoveGoalTransactionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RemoveGoalTransactionResponse();
    RemoveGoalTransactionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RemoveGoalTransactionResponse) {
    _instance.id = _instance.id || 0;
    _instance.ok = _instance.ok || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RemoveGoalTransactionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.ok = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    RemoveGoalTransactionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RemoveGoalTransactionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.ok) {
      _writer.writeBool(2, _instance.ok);
    }
  }

  private _id: number;
  private _ok: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RemoveGoalTransactionResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<RemoveGoalTransactionResponse.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.ok = _value.ok;
    RemoveGoalTransactionResponse.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get ok(): boolean {
    return this._ok;
  }
  set ok(value: boolean) {
    this._ok = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RemoveGoalTransactionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RemoveGoalTransactionResponse.AsObject {
    return {
      id: this.id,
      ok: this.ok
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RemoveGoalTransactionResponse.AsProtobufJSON {
    return {
      id: this.id,
      ok: this.ok
    };
  }
}
export module RemoveGoalTransactionResponse {
  /**
   * Standard JavaScript object representation for RemoveGoalTransactionResponse
   */
  export interface AsObject {
    id: number;
    ok: boolean;
  }

  /**
   * Protobuf JSON representation for RemoveGoalTransactionResponse
   */
  export interface AsProtobufJSON {
    id: number;
    ok: boolean;
  }
}

/**
 * Message implementation for goal_transaction.ListGoalTransactionsResponse
 */
export class ListGoalTransactionsResponse implements GrpcMessage {
  static id = 'goal_transaction.ListGoalTransactionsResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalTransactionsResponse();
    ListGoalTransactionsResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalTransactionsResponse) {
    _instance.value = _instance.value || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalTransactionsResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.GoalTransactionModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.GoalTransactionModel.deserializeBinaryFromReader
          );
          (_instance.value = _instance.value || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalTransactionsResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalTransactionsResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.value && _instance.value.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.value as any,
        common_message008.GoalTransactionModel.serializeBinaryToWriter
      );
    }
  }

  private _value?: common_message008.GoalTransactionModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalTransactionsResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListGoalTransactionsResponse.AsObject>
  ) {
    _value = _value || {};
    this.value = (_value.value || []).map(
      m => new common_message008.GoalTransactionModel(m)
    );
    ListGoalTransactionsResponse.refineValues(this);
  }
  get value(): common_message008.GoalTransactionModel[] | undefined {
    return this._value;
  }
  set value(value: common_message008.GoalTransactionModel[] | undefined) {
    this._value = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalTransactionsResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalTransactionsResponse.AsObject {
    return {
      value: (this.value || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalTransactionsResponse.AsProtobufJSON {
    return {
      value: (this.value || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ListGoalTransactionsResponse {
  /**
   * Standard JavaScript object representation for ListGoalTransactionsResponse
   */
  export interface AsObject {
    value?: common_message008.GoalTransactionModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListGoalTransactionsResponse
   */
  export interface AsProtobufJSON {
    value: common_message008.GoalTransactionModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for goal_transaction.ListGoalTransactionsRequest
 */
export class ListGoalTransactionsRequest implements GrpcMessage {
  static id = 'goal_transaction.ListGoalTransactionsRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalTransactionsRequest();
    ListGoalTransactionsRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalTransactionsRequest) {
    _instance.where = _instance.where || undefined;
    _instance.include = _instance.include || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalTransactionsRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.where = new common_message008.WhereListRequest();
          _reader.readMessage(
            _instance.where,
            common_message008.WhereListRequest.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.include = new ListGoalTransactionsRequest.Include();
          _reader.readMessage(
            _instance.include,
            ListGoalTransactionsRequest.Include.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalTransactionsRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalTransactionsRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.where) {
      _writer.writeMessage(
        1,
        _instance.where as any,
        common_message008.WhereListRequest.serializeBinaryToWriter
      );
    }
    if (_instance.include) {
      _writer.writeMessage(
        2,
        _instance.include as any,
        ListGoalTransactionsRequest.Include.serializeBinaryToWriter
      );
    }
  }

  private _where?: common_message008.WhereListRequest;
  private _include?: ListGoalTransactionsRequest.Include;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalTransactionsRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListGoalTransactionsRequest.AsObject>) {
    _value = _value || {};
    this.where = _value.where
      ? new common_message008.WhereListRequest(_value.where)
      : undefined;
    this.include = _value.include
      ? new ListGoalTransactionsRequest.Include(_value.include)
      : undefined;
    ListGoalTransactionsRequest.refineValues(this);
  }
  get where(): common_message008.WhereListRequest | undefined {
    return this._where;
  }
  set where(value: common_message008.WhereListRequest | undefined) {
    this._where = value;
  }
  get include(): ListGoalTransactionsRequest.Include | undefined {
    return this._include;
  }
  set include(value: ListGoalTransactionsRequest.Include | undefined) {
    this._include = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalTransactionsRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalTransactionsRequest.AsObject {
    return {
      where: this.where ? this.where.toObject() : undefined,
      include: this.include ? this.include.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalTransactionsRequest.AsProtobufJSON {
    return {
      where: this.where ? this.where.toProtobufJSON(options) : null,
      include: this.include ? this.include.toProtobufJSON(options) : null
    };
  }
}
export module ListGoalTransactionsRequest {
  /**
   * Standard JavaScript object representation for ListGoalTransactionsRequest
   */
  export interface AsObject {
    where?: common_message008.WhereListRequest.AsObject;
    include?: ListGoalTransactionsRequest.Include.AsObject;
  }

  /**
   * Protobuf JSON representation for ListGoalTransactionsRequest
   */
  export interface AsProtobufJSON {
    where: common_message008.WhereListRequest.AsProtobufJSON | null;
    include: ListGoalTransactionsRequest.Include.AsProtobufJSON | null;
  }

  /**
   * Message implementation for goal_transaction.ListGoalTransactionsRequest.Include
   */
  export class Include implements GrpcMessage {
    static id = 'goal_transaction.ListGoalTransactionsRequest.Include';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new Include();
      Include.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: Include) {
      _instance.goalTransactionFunding =
        _instance.goalTransactionFunding || undefined;
      _instance.goal = _instance.goal || undefined;
      _instance.financialentity = _instance.financialentity || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: Include,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 2:
            _instance.goalTransactionFunding = new googleProtobuf000.BoolValue();
            _reader.readMessage(
              _instance.goalTransactionFunding,
              googleProtobuf000.BoolValue.deserializeBinaryFromReader
            );
            break;
          case 3:
            _instance.goal = new googleProtobuf000.BoolValue();
            _reader.readMessage(
              _instance.goal,
              googleProtobuf000.BoolValue.deserializeBinaryFromReader
            );
            break;
          case 4:
            _instance.financialentity = new googleProtobuf000.BoolValue();
            _reader.readMessage(
              _instance.financialentity,
              googleProtobuf000.BoolValue.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      Include.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(_instance: Include, _writer: BinaryWriter) {
      if (_instance.goalTransactionFunding) {
        _writer.writeMessage(
          2,
          _instance.goalTransactionFunding as any,
          googleProtobuf000.BoolValue.serializeBinaryToWriter
        );
      }
      if (_instance.goal) {
        _writer.writeMessage(
          3,
          _instance.goal as any,
          googleProtobuf000.BoolValue.serializeBinaryToWriter
        );
      }
      if (_instance.financialentity) {
        _writer.writeMessage(
          4,
          _instance.financialentity as any,
          googleProtobuf000.BoolValue.serializeBinaryToWriter
        );
      }
    }

    private _goalTransactionFunding?: googleProtobuf000.BoolValue;
    private _goal?: googleProtobuf000.BoolValue;
    private _financialentity?: googleProtobuf000.BoolValue;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of Include to deeply clone from
     */
    constructor(_value?: RecursivePartial<Include.AsObject>) {
      _value = _value || {};
      this.goalTransactionFunding = _value.goalTransactionFunding
        ? new googleProtobuf000.BoolValue(_value.goalTransactionFunding)
        : undefined;
      this.goal = _value.goal
        ? new googleProtobuf000.BoolValue(_value.goal)
        : undefined;
      this.financialentity = _value.financialentity
        ? new googleProtobuf000.BoolValue(_value.financialentity)
        : undefined;
      Include.refineValues(this);
    }
    get goalTransactionFunding(): googleProtobuf000.BoolValue | undefined {
      return this._goalTransactionFunding;
    }
    set goalTransactionFunding(value: googleProtobuf000.BoolValue | undefined) {
      this._goalTransactionFunding = value;
    }
    get goal(): googleProtobuf000.BoolValue | undefined {
      return this._goal;
    }
    set goal(value: googleProtobuf000.BoolValue | undefined) {
      this._goal = value;
    }
    get financialentity(): googleProtobuf000.BoolValue | undefined {
      return this._financialentity;
    }
    set financialentity(value: googleProtobuf000.BoolValue | undefined) {
      this._financialentity = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      Include.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): Include.AsObject {
      return {
        goalTransactionFunding: this.goalTransactionFunding
          ? this.goalTransactionFunding.toObject()
          : undefined,
        goal: this.goal ? this.goal.toObject() : undefined,
        financialentity: this.financialentity
          ? this.financialentity.toObject()
          : undefined
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): Include.AsProtobufJSON {
      return {
        goalTransactionFunding: this.goalTransactionFunding
          ? this.goalTransactionFunding.toProtobufJSON(options)
          : null,
        goal: this.goal ? this.goal.toProtobufJSON(options) : null,
        financialentity: this.financialentity
          ? this.financialentity.toProtobufJSON(options)
          : null
      };
    }
  }
  export module Include {
    /**
     * Standard JavaScript object representation for Include
     */
    export interface AsObject {
      goalTransactionFunding?: googleProtobuf000.BoolValue.AsObject;
      goal?: googleProtobuf000.BoolValue.AsObject;
      financialentity?: googleProtobuf000.BoolValue.AsObject;
    }

    /**
     * Protobuf JSON representation for Include
     */
    export interface AsProtobufJSON {
      goalTransactionFunding: googleProtobuf000.BoolValue.AsProtobufJSON | null;
      goal: googleProtobuf000.BoolValue.AsProtobufJSON | null;
      financialentity: googleProtobuf000.BoolValue.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for goal_transaction.UploadVoucherRequest
 */
export class UploadVoucherRequest implements GrpcMessage {
  static id = 'goal_transaction.UploadVoucherRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadVoucherRequest();
    UploadVoucherRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadVoucherRequest) {
    _instance.voucher = _instance.voucher || undefined;
    _instance.transactions = _instance.transactions || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadVoucherRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.voucher = new file_message007.FileModel();
          _reader.readMessage(
            _instance.voucher,
            file_message007.FileModel.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new UploadVoucherTransactionRequest();
          _reader.readMessage(
            messageInitializer2,
            UploadVoucherTransactionRequest.deserializeBinaryFromReader
          );
          (_instance.transactions = _instance.transactions || []).push(
            messageInitializer2
          );
          break;
        default:
          _reader.skipField();
      }
    }

    UploadVoucherRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadVoucherRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.voucher) {
      _writer.writeMessage(
        1,
        _instance.voucher as any,
        file_message007.FileModel.serializeBinaryToWriter
      );
    }
    if (_instance.transactions && _instance.transactions.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.transactions as any,
        UploadVoucherTransactionRequest.serializeBinaryToWriter
      );
    }
  }

  private _voucher?: file_message007.FileModel;
  private _transactions?: UploadVoucherTransactionRequest[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadVoucherRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadVoucherRequest.AsObject>) {
    _value = _value || {};
    this.voucher = _value.voucher
      ? new file_message007.FileModel(_value.voucher)
      : undefined;
    this.transactions = (_value.transactions || []).map(
      m => new UploadVoucherTransactionRequest(m)
    );
    UploadVoucherRequest.refineValues(this);
  }
  get voucher(): file_message007.FileModel | undefined {
    return this._voucher;
  }
  set voucher(value: file_message007.FileModel | undefined) {
    this._voucher = value;
  }
  get transactions(): UploadVoucherTransactionRequest[] | undefined {
    return this._transactions;
  }
  set transactions(value: UploadVoucherTransactionRequest[] | undefined) {
    this._transactions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadVoucherRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadVoucherRequest.AsObject {
    return {
      voucher: this.voucher ? this.voucher.toObject() : undefined,
      transactions: (this.transactions || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadVoucherRequest.AsProtobufJSON {
    return {
      voucher: this.voucher ? this.voucher.toProtobufJSON(options) : null,
      transactions: (this.transactions || []).map(m =>
        m.toProtobufJSON(options)
      )
    };
  }
}
export module UploadVoucherRequest {
  /**
   * Standard JavaScript object representation for UploadVoucherRequest
   */
  export interface AsObject {
    voucher?: file_message007.FileModel.AsObject;
    transactions?: UploadVoucherTransactionRequest.AsObject[];
  }

  /**
   * Protobuf JSON representation for UploadVoucherRequest
   */
  export interface AsProtobufJSON {
    voucher: file_message007.FileModel.AsProtobufJSON | null;
    transactions: UploadVoucherTransactionRequest.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for goal_transaction.UploadVoucherTransactionRequest
 */
export class UploadVoucherTransactionRequest implements GrpcMessage {
  static id = 'goal_transaction.UploadVoucherTransactionRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadVoucherTransactionRequest();
    UploadVoucherTransactionRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadVoucherTransactionRequest) {
    _instance.id = _instance.id || 0;
    _instance.amountToProcess = _instance.amountToProcess || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadVoucherTransactionRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readInt32();
          break;
        case 2:
          _instance.amountToProcess = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    UploadVoucherTransactionRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadVoucherTransactionRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeInt32(1, _instance.id);
    }
    if (_instance.amountToProcess) {
      _writer.writeDouble(2, _instance.amountToProcess);
    }
  }

  private _id: number;
  private _amountToProcess: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadVoucherTransactionRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<UploadVoucherTransactionRequest.AsObject>
  ) {
    _value = _value || {};
    this.id = _value.id;
    this.amountToProcess = _value.amountToProcess;
    UploadVoucherTransactionRequest.refineValues(this);
  }
  get id(): number {
    return this._id;
  }
  set id(value: number) {
    this._id = value;
  }
  get amountToProcess(): number {
    return this._amountToProcess;
  }
  set amountToProcess(value: number) {
    this._amountToProcess = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadVoucherTransactionRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadVoucherTransactionRequest.AsObject {
    return {
      id: this.id,
      amountToProcess: this.amountToProcess
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadVoucherTransactionRequest.AsProtobufJSON {
    return {
      id: this.id,
      amountToProcess: this.amountToProcess
    };
  }
}
export module UploadVoucherTransactionRequest {
  /**
   * Standard JavaScript object representation for UploadVoucherTransactionRequest
   */
  export interface AsObject {
    id: number;
    amountToProcess: number;
  }

  /**
   * Protobuf JSON representation for UploadVoucherTransactionRequest
   */
  export interface AsProtobufJSON {
    id: number;
    amountToProcess: number;
  }
}

/**
 * Message implementation for goal_transaction.UploadVoucherResponse
 */
export class UploadVoucherResponse implements GrpcMessage {
  static id = 'goal_transaction.UploadVoucherResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UploadVoucherResponse();
    UploadVoucherResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UploadVoucherResponse) {
    _instance.correct = _instance.correct || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UploadVoucherResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.correct = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UploadVoucherResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UploadVoucherResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.correct) {
      _writer.writeBool(1, _instance.correct);
    }
  }

  private _correct: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UploadVoucherResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UploadVoucherResponse.AsObject>) {
    _value = _value || {};
    this.correct = _value.correct;
    UploadVoucherResponse.refineValues(this);
  }
  get correct(): boolean {
    return this._correct;
  }
  set correct(value: boolean) {
    this._correct = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UploadVoucherResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UploadVoucherResponse.AsObject {
    return {
      correct: this.correct
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UploadVoucherResponse.AsProtobufJSON {
    return {
      correct: this.correct
    };
  }
}
export module UploadVoucherResponse {
  /**
   * Standard JavaScript object representation for UploadVoucherResponse
   */
  export interface AsObject {
    correct: boolean;
  }

  /**
   * Protobuf JSON representation for UploadVoucherResponse
   */
  export interface AsProtobufJSON {
    correct: boolean;
  }
}

/**
 * Message implementation for goal_transaction.ListGoalTransactionResponse
 */
export class ListGoalTransactionResponse implements GrpcMessage {
  static id = 'goal_transaction.ListGoalTransactionResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListGoalTransactionResponse();
    ListGoalTransactionResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListGoalTransactionResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListGoalTransactionResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message008.GoalTransactionModel();
          _reader.readMessage(
            messageInitializer1,
            common_message008.GoalTransactionModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListGoalTransactionResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListGoalTransactionResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message008.GoalTransactionModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message008.GoalTransactionModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListGoalTransactionResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListGoalTransactionResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message008.GoalTransactionModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListGoalTransactionResponse.refineValues(this);
  }
  get values(): common_message008.GoalTransactionModel[] | undefined {
    return this._values;
  }
  set values(value: common_message008.GoalTransactionModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListGoalTransactionResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListGoalTransactionResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListGoalTransactionResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListGoalTransactionResponse {
  /**
   * Standard JavaScript object representation for ListGoalTransactionResponse
   */
  export interface AsObject {
    values?: common_message008.GoalTransactionModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListGoalTransactionResponse
   */
  export interface AsProtobufJSON {
    values: common_message008.GoalTransactionModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}
